export const rolesConstants = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'superAdmin',
  REGION_USER: 'regionUser',
  ACCOUNT_MANAGER: 'accountManager',
  BRAND_MANAGER: 'brandManager',
  BRAND_VENDOR: 'brandVendor',
  BRANCH_CUSTOMER_CARE: 'branchCustomerCare',
  ADMIN_OMS: 'adminOMS',
  HO_USER: 'HOUser',
  SECURITY_GUARD: 'securityGuard',
  CUSTOMER_CARE_MANAGER: 'customerCareManager',
  CUSTOMER_CARE_USER: 'customerCareUser',
  TEAMLEAD: 'teamlead',
  INCHARGE_WMS: 'inchargeWMS',
  INCHARGE_DMS: 'inchargeDMS',
  INCHARGE_OMS: 'inchargeOMS',
  FINANCE: 'finance',
  // PRODUCTS_INCHARGE:'productsIncharge'
};
