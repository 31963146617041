import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentInputValidate, unpaidTripsColumns } from './utils';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { generateRandomId } from '../../utils/string';
import TableOnly from '../scheme-management/Components/IndividualScheme.js/TableOnly';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import { ErrorMessage } from '../scheme-management/Components/StyledComponents';
import DatePicker from './DatePicker';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import {
  getAllTripNumbers,
  getMultipleTripsByTripNumbers,
} from '../../parse-functions/trips';
import { debounce } from '../../utils/debounce';
import AsyncDropdown from '../../components/AsyncDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { StyledSearchBar } from '../trips/styles';
import { savePaymentData } from '../../parse-functions/payments';
import { returnUserReadableDate } from '../../utils/datetime';

const TripsPaymentModal = (props) => {
  const { showModal, closeModal, tripsData, deliveryRate } = props;
  const [finalTripsData, setFinalTripsData] = useState(
    tripsData.map((item) => ({
      ...item,
      deliveryRateInfo: {
        ...item.deliveryRateInfo,
        finalPayment: item.totalAmount || 0,
      },
    }))
  );
  const [showFinalPaymentModal, setShowFinalPaymentModal] = useState(false);
  const [showAddTripModal, setShowAddTripModal] = useState(false);
  const [formInput, setFormInput] = useState({
    paymentMethod: '',
    invoiceNumber: '',
    invoiceDate: '',
    paymentDate: '',
    paymentRemarks: '',
  });
  const [formErrors, setFormErrors] = useState({
    paymentMethod: '',
    invoiceNumber: '',
    invoiceDate: '',
    paymentDate: '',
    paymentRemarks: '',
  });
  const [newTrips, setNewTrips] = useState([]);
  const [newTripNumber, setNewTripNumber] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getDaysInCurrentMonth = (date) => {
    const now = new Date(date);
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  };

  const handleInputChange = (row, e, type) => {
    const { id, value } = e.target;
    // Find the delivery rate for the specific zone
    const delivery = deliveryRate.find(
      (item) => item.zone === row.driverInfo?.zone
    )?.rate;
    setFinalTripsData((prevData) =>
      prevData.map((item) => {
        if (item.objectId === id) {
          let updatedDeliveryRateInfo = {
            ...item.deliveryRateInfo,
            [type]: value,
          };
          if (type !== 'finalPayment') {
            updatedDeliveryRateInfo.finalPayment =
              ((parseInt(updatedDeliveryRateInfo.defectivePicked) || 0) +
                (parseInt(updatedDeliveryRateInfo.deliveryQuantity) || 0) +
                (parseInt(updatedDeliveryRateInfo.package) || 0)) *
              delivery;
          }
          return {
            ...item,
            deliveryRateInfo: updatedDeliveryRateInfo,
          };
        }
        return item;
      })
    );
  };

  const columns = [
    {
      dataField: 'tripNumber',
      text: 'Trip No.',
    },
    {
      dataField: 'driverInfo',
      text: 'Vendor Name',
      formatter: (cell, row) => {
        return <div className="d-block">{cell.vendorName}</div>;
      },
    },
    {
      dataField: 'driverInfo',
      text: 'Vehicle Details',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div>{cell?.vehicleNumber}</div>
            <div>{cell?.paymentMode}</div>
            <div>{cell?.vehicleType}</div>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryRateInfo',
      text: 'Delivery Quantity',
      formatter: (cell, row) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.objectId}
              value={cell?.deliveryQuantity}
              // placeholder={value?.[row.objectId]?.deliveryQuantity}
              onChange={(e) => handleInputChange(row, e, 'deliveryQuantity')}
              style={{ width: '100px' }}
            />
          </Form.Group>
        );
      },
    },
    {
      dataField: 'deliveryRateInfo',
      text: 'Defective Picked',
      formatter: (cell, row) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.objectId}
              value={cell?.defectivePicked}
              onChange={(e) => handleInputChange(row, e, 'defectivePicked')}
              style={{ width: '100px' }}
            />
          </Form.Group>
        );
      },
    },
    {
      dataField: 'deliveryRateInfo',
      text: 'Package',
      formatter: (cell, row) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.objectId}
              value={cell?.package}
              onChange={(e) => handleInputChange(row, e, 'package')}
              style={{ width: '100px' }}
            />
          </Form.Group>
        );
      },
    },
    {
      dataField: 'tripDate',
      text: 'Trip Date',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableDate(row.dispatchedAt)}
          </div>
        );
      },
    },
    {
      dataField: 'deliveryRateInfo',
      text: 'Calculation',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {['fixedPerDelivery', 'extraPerDelivery'].includes(
              row?.driverInfo?.paymentMode
            ) ? (
              <>
                <div>{`${cell.deliveryQuantity} x ${row?.perDeliveryRate} = ${
                  cell?.deliveryQuantity * row?.perDeliveryRate || 0
                }`}</div>
                <div>{`${cell.defectivePicked} x ${row?.perDeliveryRate} = ${
                  cell?.defectivePicked * row?.perDeliveryRate || 0
                }`}</div>
                <div>{`${cell.package} x ${row?.perDeliveryRate} = ${
                  cell?.package * row?.perDeliveryRate || 0
                }`}</div>
                {row?.driverInfo?.zone !== 'Mumbai' && (
                  <div>{`${cell.droppedAtHub} x ${row?.perDeliveryRate} = ${
                    cell?.droppedAtHub * row?.perDeliveryRate || 0
                  }`}</div>
                )}
              </>
            ) : (
              <>{`${row?.driverInfo?.paymentAmount} / ${getDaysInCurrentMonth(
                row.dispatchedAt
              )}`}</>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
    },
    {
      dataField: 'deliveryRateInfo',
      text: 'Final Payment',
      formatter: (cell, row) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.objectId}
              value={cell.finalPayment}
              onChange={(e) => handleInputChange(row, e, 'finalPayment')}
              style={{ width: '100px' }}
            />
          </Form.Group>
        );
      },
    },
  ];
  const fetchOptions = debounce(async (inputValue) => {
    if (inputValue.length <= 1) return [];
    try {
      const res = await getAllTripNumbers({
        filter: inputValue.toUpperCase(),
      });
      const filtered = res.filter((option) => !newTripNumber.includes(option));
      const options = arrayToDropdownOptions(filtered);
      return options;
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: t('failed to get trips!'),
          message: t(error.message),
        })
      );
    }
  });

  const handleConfirmClick = async () => {
    try {
      const valid = paymentInputValidate(formInput, setFormErrors);
      if (!valid) {
        return;
      }
      dispatch(updateSpinnerState(true));
      const data = {
        ...formInput,
        totalAmount:
          finalTripsData.reduce((acc, item) => acc + item.totalAmount, 0) || 0,
        tripNumbers: finalTripsData.map((item) => item.tripNumber),
        vendorName: finalTripsData[0].driverInfo?.vendorName,
        vendorId: finalTripsData[0].driverInfo?.vendorObjectId,
        totalTrips: finalTripsData.length,
        finalPayment:
          finalTripsData.reduce(
            (acc, item) =>
              acc + parseFloat(item?.deliveryRateInfo?.finalPayment),
            0
          ) || 0,
      };
      const tripsIds = finalTripsData.map((item) => ({
        objectId: item.objectId,
        finalPayment: parseInt(item?.deliveryRateInfo?.finalPayment),
        finalDeliveryRateInfo: {
          deliveryQuantity: parseInt(
            item?.deliveryRateInfo?.deliveryQuantity || 0
          ),
          defectivePicked: parseInt(
            item?.deliveryRateInfo?.defectivePicked || 0
          ),
          package: parseInt(item?.deliveryRateInfo?.package || 0),
        },
      }));
      await savePaymentData({ tripsIds, data });
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Data saved successfully!'),
        })
      );
      setShowFinalPaymentModal(false);
      closeModal();
    } catch (error) {
      if (error.message.invoiceNumber) {
        setFormErrors((prev) => ({
          ...prev,
          invoiceNumber: error.message.invoiceNumber,
        }));
      }
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t(''),
          message: t('Failed to save payment information!'),
        })
      );
    }
  };
  const handleNewTripSaveClick = () => {
    setFinalTripsData((prev) => [...newTrips, ...prev]);
    setShowAddTripModal(false);
  };

  const handleMarkPaid = () => {
    if (finalTripsData.length === 0) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t(''),
          message: t('Minimum 1 trip'),
        })
      );
      return;
    }
    setShowFinalPaymentModal(true);
  };

  const handleAddTrip = async () => {
    const res = await getMultipleTripsByTripNumbers({
      tripNumbers: newTripNumber,
    });
    setNewTrips(res);
    setNewTripNumber([]);
  };

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title className="h6 d-flex justify-content-between w-100">
            <div>{t('Payment for Selected trips')}</div>
            <Button
              variant="primary"
              onClick={() => {
                setShowAddTripModal(true);
              }}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
              }}
              size="sm"
            >
              <FontAwesomeIcon icon={faAdd} />
              &nbsp;&nbsp;{t('Add New Trips')}
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            <Form.Group>
              <Form.Label>Selected Trips</Form.Label>
              <StyledSearchBar
                width={'250px'}
                type="number"
                height={'35px'}
                placeholder={finalTripsData.length}
                disabled={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Total Amount</Form.Label>
              <StyledSearchBar
                width={'250px'}
                type="number"
                height={'35px'}
                placeholder={parseInt(
                  finalTripsData.reduce(
                    (acc, item) =>
                      acc + Math.round((item.totalAmount || 0) * 100) / 100,
                    0
                  )
                )}
                disabled={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Total Final Payment</Form.Label>
              <StyledSearchBar
                width={'250px'}
                type="number"
                height={'35px'}
                placeholder={parseInt(
                  finalTripsData.reduce(
                    (acc, item) =>
                      acc +
                      Math.round(
                        (item?.deliveryRateInfo?.finalPayment || 0) * 100
                      ) /
                        100,
                    0
                  )
                )}
                disabled={true}
              />
            </Form.Group>
          </div>
          <TableOnly
            data={finalTripsData}
            columns={columns}
            id={'unpaidTripsModal'}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleMarkPaid}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
            }}
            size="sm"
          >
            {t('Mark as Paid')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showFinalPaymentModal}
        onHide={() => setShowFinalPaymentModal(false)}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">{t('Payment Information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
                backgroundColor: '#eaedf2',
              }}
              type="text"
              value={formInput['paymentMethod']}
              isInvalid={formErrors['paymentMethod']}
              onChange={(e) => {
                e.persist();
                setFormInput((prev) => ({
                  ...prev,
                  paymentMethod: e.target.value,
                }));
              }}
              placeholder="Enter the Payment Method."
            />
            <ErrorMessage>{t(formErrors['paymentMethod'])}</ErrorMessage>
          </Form.Group>
          <Form.Group>
            <Form.Label>Payment Date</Form.Label>
            <DatePicker
              value={formInput['paymentDate']}
              setValue={(date) =>
                setFormInput((prev) => ({
                  ...prev,
                  paymentDate: date,
                }))
              }
              isInvalid={formErrors['paymentDate']}
              errorMessage={t(formErrors['paymentDate'])}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Invoice Number</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
                backgroundColor: '#eaedf2',
              }}
              type="text"
              value={formInput['invoiceNumber']}
              isInvalid={formErrors['invoiceNumber']}
              onChange={(e) => {
                e.persist();
                setFormInput((prev) => ({
                  ...prev,
                  invoiceNumber: e.target.value,
                }));
              }}
              placeholder="Enter the Invoice No."
            />
            <ErrorMessage>{t(formErrors['invoiceNumber'])}</ErrorMessage>
          </Form.Group>
          <Form.Group>
            <Form.Label>Invoice Date</Form.Label>
            <DatePicker
              value={formInput['invoiceDate']}
              setValue={(date) =>
                setFormInput((prev) => ({
                  ...prev,
                  invoiceDate: date,
                }))
              }
              isInvalid={formErrors['invoiceDate']}
              errorMessage={t(formErrors['invoiceDate'])}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Payment Remarks</Form.Label>
            <Form.Control
              style={{
                width: '100%',
                backgroundColor: '#eaedf2',
              }}
              as="textarea"
              rows={3}
              value={formInput['paymentRemarks']}
              isInvalid={formErrors['paymentRemarks']}
              onChange={(e) => {
                e.persist();
                setFormInput((prev) => ({
                  ...prev,
                  paymentRemarks: e.target.value,
                }));
              }}
              placeholder="Enter the remarks"
            />
            <ErrorMessage>{t(formErrors['paymentRemarks'])}</ErrorMessage>
          </Form.Group>
          <Form.Group>
            <Form.Label>Total Final Payment</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
                backgroundColor: '#eaedf2',
              }}
              type="text"
              value={parseInt(
                finalTripsData.reduce(
                  (acc, item) =>
                    acc +
                    Math.round(
                      (item?.deliveryRateInfo?.finalPayment || 0) * 100
                    ) /
                      100,
                  0
                )
              )}
              disabled={true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-gray ms-auto"
            onClick={() => setShowFinalPaymentModal(false)}
          >
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleConfirmClick}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showAddTripModal}
        onHide={() => setShowAddTripModal(false)}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">{t('Add New Trip')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('Temposheet No.')}</Form.Label>
            <div className="d-flex gap-4 mb-4">
              <AsyncDropdown
                onChange={(option) =>
                  setNewTripNumber(option.map((opt) => opt.value))
                }
                width={600}
                fetchOptions={fetchOptions}
                placeholder={t('Search/Type TripNumber')}
                isMultiSelect={true}
              />
              <Button
                onClick={handleAddTrip}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
                size="sm"
              >
                <FontAwesomeIcon icon={faAdd} />
                &nbsp;&nbsp; {t('Add Trip')}
              </Button>
            </div>
            <TableOnly
              data={newTrips}
              columns={unpaidTripsColumns({
                isModal: true,
                isDelete: true,
                setFunction: setNewTrips,
                isdisabled: true,
                isFinalTextField: true,
              })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNewTripSaveClick}>
            {t('Save')}
          </Button>
          <Button
            variant="secondary"
            className="text-gray ms-auto"
            onClick={() => setShowAddTripModal(false)}
          >
            {t('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TripsPaymentModal;
