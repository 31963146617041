import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@themesberg/react-bootstrap';
import React from 'react';

const UnableToGenerateModal = ({ show, handleClose, onSuccess, onCancel }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      style={{
        textAlign: 'center',
      }}
    >
      {/* Modal Header with Close Button */}
      <Modal.Header closeButton style={{ border: 'none', height: '10px' }}>
        <Modal.Title style={{ display: 'none' }}>
          Unable to Generate
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationCircle}
          size={'xl'}
          style={{ marginBlock: '1px' }}
        />
        <h5
          style={{
            fontWeight: '600',
            fontSize: '22px',
            color: '#333',
            marginBottom: '15px',
            marginBlock: '10px',
          }}
        >
          Unable to Generate E-Way Bill
        </h5>
        <p
          style={{
            fontSize: '14px',
            color: '#555',
            lineHeight: '1.6',
            marginBottom: '20px',
          }}
        >
          Our system has detected that an E-Way Bill is already generated for
          this order. You can choose one of the following options:
        </p>
        <ol
          style={{
            padding: '0 20px',
            textAlign: 'left',
            margin: '0 auto',
            display: 'inline-block',
            fontSize: '14px',
            color: '#555',
          }}
        >
          <li style={{ marginBottom: '10px' }}>
            Cancel the existing E-Way Bill and generate a new one.
          </li>
          <li>Enter the E-Way Bill number manually to continue.</li>
        </ol>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '50px',
            width: '80%',
          }}
        >
          <Button
            variant="link"
            className="text-gray"
            style={{ borderRadius: '0px', backgroundColor: '#F0F0F0' }}
            onClick={onCancel}
          >
            1. Cancel and Generate Again
          </Button>
          <Button
            variant="primary"
            style={{ borderRadius: '0px', backgroundColor: '#444444' }}
            onClick={onSuccess}
          >
            2. Enter E-Way Bill Number
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnableToGenerateModal;
