import React from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';

const CourierPickup = () => {
  const bulkActionOptions = [
    {
      label: 'Picked Up',
      value: 'pickedUp',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
    {
      label: 'Generate Manifest',
      value: 'Generate Manifest',
    },
  ];
  return (
    <div>
      <OrderPage
        type={orderStatus.readyForPickup}
        bulkActionOptions={bulkActionOptions}
        is3PLDelivery={true}
      />
    </div>
  );
};

export default CourierPickup;
