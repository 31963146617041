import { RESET_FILEDATA_IN_STORE, SET_FILEDATA_IN_STORE } from '../types';

export const setFileDataInStore = (data) => {
  return {
    type: SET_FILEDATA_IN_STORE,
    data,
  };
};

export const resetFileDataInStore = () => {
  return {
    type: RESET_FILEDATA_IN_STORE,
  };
};
