import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateFileModal from './UpdateFileModal';

const UploadFile = ({
  uploadedData,
  sampleFileUrl,
  buttonText = 'Bulk Update',
  buttonSize = 'sm',
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleFileUploaded = (fileData) => {
    setShowModal(false);
    uploadedData(fileData);
  };

  return (
    <>
      <Button
        variant="white"
        size={buttonSize}
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faDownload} size={'sm'} /> &nbsp;&nbsp;
        {t(buttonText)}
      </Button>

      {showModal && (
        <UpdateFileModal
          showModal={showModal}
          sampleFileUrl={sampleFileUrl}
          closeModal={() => setShowModal(false)}
          handleSubmit={handleFileUploaded}
        />
      )}
    </>
  );
};

export default UploadFile;
