import { Card, ProgressBar } from '@themesberg/react-bootstrap';
import React from 'react';
import ExcelDownloadReport from '../../../components/common/excel/download';

function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hours ${remainingMinutes} mins`;
}

export const StoreProgressCards = ({ data }) => {
  const { globalAverages, selectedStores } = data;

  return (
    <div>
      <div className="d-flex justify-content-end"></div>
      {Object.keys(selectedStores).map((storeKey) => {
        const store = selectedStores[storeKey];
        return (
          <Card key={store.storeCode} className="mb-3 w-auto d-flex flex-wrap">
            <div className="card-body">
              <h5 className="card-title">{store.storeCode}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{store.city}</h6>
              <div className="d-flex flex-wrap justify-content-between align-items-center gap-3">
                {Object.keys(globalAverages).map((key) => {
                  const globalAvg = globalAverages[key]?.avg;
                  const storeValue = store[key]?.avg || 0;

                  return (
                    <div key={key}>
                      <span
                        style={{
                          fontSize: '0.8rem',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                          marginBottom: '0.5rem',
                        }}
                      >
                        {convertMinutesToHoursAndMinutes(Math.ceil(storeValue))}
                      </span>
                      <ProgressBar
                        now={(storeValue / globalAvg) * 100}
                        variant={storeValue > globalAvg ? 'danger' : 'primary'}
                      />
                      <span style={{ flex: 1, fontSize: '0.9rem' }}>{key}</span>
                      <br />
                      <span style={{ flex: 1, fontSize: '0.9rem' }}>
                        No of Orders: {store[key].count}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
