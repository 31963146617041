import { Modal } from '@themesberg/react-bootstrap';
import React from 'react';
import { generateRandomId } from '../../../../utils/string';
import FileUploadButton from '../../../../components/common/fileUpload';
import { convertExcelDataToJSONRows } from '../../../../utils/excel';

const UpdateFileModal = ({
  showModal,
  closeModal,
  sampleFileUrl,
  handleSubmit,
}) => {
  const fileUploadComplete = (fileData) => {
    const firstSNoIndex = fileData?.rows?.findIndex((arr) =>
      arr?.includes('SNo')
    );
    const allColumns = fileData?.rows[firstSNoIndex];
    const allRows = fileData?.rows.slice(
      firstSNoIndex + 1,
      fileData?.rows.length
    );
    let fileuploadedData = convertExcelDataToJSONRows(allColumns, allRows);
    handleSubmit(fileuploadedData);
  };

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="lg"
      >
        <Modal.Body>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '10px',
            }}
          >
            <FileUploadButton
              buttonText={'Upload File'}
              onFileUpload={fileUploadComplete}
              style={{
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            />
            <div style={{ color: '#888', margin: '10px 0' }}>or</div>
            <div
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#007bff',
              }}
              onClick={() => {
                window.open(sampleFileUrl, '_blank');
              }}
            >
              Download Template from here
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateFileModal;
