import React, { useState } from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';

const PendingAction = () => {
  const bulkActionOptions = [
    {
      label: 'Assign',
      value: 'assign',
    },
    {
      label: 'Out Of Stock',
      value: 'Out Of Stock',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
  ];
  return (
    <div>
      <OrderPage
        type={orderStatus.pendingAction}
        bulkActionOptions={bulkActionOptions}
      />
    </div>
  );
};

export default PendingAction;
