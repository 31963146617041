import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Joi from 'joi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import { returnValidationErrorMessages } from '../../utils/validation';
import { isValidGSTNumber } from '../../utils/vendors';
import { addNewVehicleVendor } from '../../parse-functions/vendors';

const addVendorSchema = Joi.object({
  vendorName: Joi.string().required(),
  vendorCode: Joi.string().allow(''),
  emailId: Joi.string()
    .allow('')
    .email({ tlds: { allow: false } }),
  contactNumber: Joi.number().required().greater(1000000000).less(9999999999),
  gstNumber: Joi.string()
    .required()
    .custom(isValidGSTNumber, 'Invalid GST Number Format'),
});
const AddVendor = () => {
  const { t } = useTranslation();
  const warehouseZone = useSelector((state) => state.user.warehouse.zone || '');
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.user.config || {});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    vendorName: '',
    vendorCode: '',
    emailId: '',
    contactNumber: '',
    gstNumber: '',
    zone: warehouseZone,
  });
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const onFormInputChange = (fieldName, value) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };
  const handleFormSubmit = async () => {
    const { error: formErrors = {}, value: formValue } =
      addVendorSchema.validate(formData, {
        abortEarly: false,
        allowUnknown: true,
      });
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      dispatch(updateSpinnerState(true));
      try {
        if (!formValue.zone) {
          formValue.zone = warehouseZone;
        }
        const savedVendor = await addNewVehicleVendor(formValue);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t('Vendor Added'),
            message: t(`${formData.vendorName} added successfully`),
          })
        );
      } catch (error) {
        // inside error
        const { message, code } = error;
        setFormErrors(message);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Vendor Add Failed'),
            message: t(`${formData.vendorName} failed to add`),
          })
        );
      }
      dispatch(updateSpinnerState(false));
    }
  };
  return (
    <div className="mt-1">
      <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Vendor')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('Add')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t('Add New Vendor')}</h4>
          <p className="mb-0">
            {t('Use this page to add a new vehicle vendor')}
          </p>
        </div>
      </div>

      <Row className="mt-3">
        <Col />
        <Col>
          <Alert variant="info">
            <FontAwesomeIcon icon={faHome} />
            &nbsp;&nbsp;{t('Vendor Info')}
          </Alert>
          <Form.Group className="mb-3 mt-4">
            <Form.Label>{t('Vendor Name')}</Form.Label>
            <Form.Control
              required
              isInvalid={formErrors['vendorName']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('vendorName', event.target.value);
              }}
            />
            {formErrors['vendorName']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter Vendor name.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('Vendor Code')}</Form.Label>
            <Form.Control
              isInvalid={formErrors['vendorCode']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('vendorCode', event.target.value);
              }}
            />
            {formErrors['vendorCode']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter vendorCode.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('Vendor Email')}</Form.Label>
            <Form.Control
              isInvalid={formErrors['emailId']?.length}
              type="email"
              onChange={(event) => {
                onFormInputChange('emailId', event.target.value);
              }}
            />
            {formErrors['emailId']?.length && (
              <Form.Control.Feedback type="invalid">
                {t(formErrors['emailId'])}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('Vendor Contact')}</Form.Label>
            <Form.Control
              isInvalid={formErrors['contactNumber']?.length}
              type="number"
              onChange={(event) => {
                onFormInputChange('contactNumber', event.target.value);
              }}
            />
            {formErrors['contactNumber']?.length && (
              <Form.Control.Feedback type="invalid">
                {t(formErrors['contactNumber'])}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('GST Number')}</Form.Label>
            <Form.Control
              isInvalid={formErrors['gstNumber']?.length}
              type="string"
              onChange={(event) => {
                onFormInputChange('gstNumber', event.target.value);
              }}
            />
            {formErrors['gstNumber']?.length && (
              <Form.Control.Feedback type="invalid">
                {t(formErrors['gstNumber'])}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('Zone')}</Form.Label>
            <Form.Select
              disabled={!isAdmin}
              onChange={(event) => {
                onFormInputChange('zone', event.target.value);
              }}
              value={formData.zone}
            >
              {isAdmin ? (
                <>
                  {Object.keys(configData?.zones).map((item) => (
                    <option>{item}</option>
                  ))}
                </>
              ) : (
                <option>{warehouseZone}</option>
              )}
            </Form.Select>
            {formErrors['zone']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please choose a zone')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col />
      </Row>
      <Row className="d-flex justify-content-between mt-3">
        <Col />
        <Col>
          <Button
            style={{ width: '98%' }}
            className="mx-2"
            variant="primary"
            onClick={handleFormSubmit}
          >
            {t('Add Vendor')}
          </Button>
          <Button
            style={{ width: '98%' }}
            className="mx-2 mt-3"
            variant="outline"
          >
            {t('Cancel')}
          </Button>
        </Col>
        <Col />
      </Row>
    </div>
  );
};
export default AddVendor;
