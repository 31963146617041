import React, { useState } from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';
import { useSelector } from 'react-redux';
import { apiCall } from '../../../middlewares/api';

const AwaitingInvoice = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const bulkActionOptions = [
    {
      label: 'Invoiced',
      value: 'invoiced',
    },
  ];

  const uploadButtonProps = {
    url : 'https://vs-system-templates.s3.ap-south-1.amazonaws.com/To+Be+Invoiced+Sample+.xlsx',
    text : 'Upload Bulk Orders',
    infoModalTitle : 'To Be Invoiced Orders',
    targetStatus : "To Be Invoiced",
    handleSubmit : async (data) => {

      try{
         const toCallData = data.map((each) => {
           return {
              orderNo : each?.OrderNo || each?.orderNo,
              itemLineNo : each?.ItemLineNo|| each?.itemLineNo,
              remarks : each?.Remarks
           }
         })
         await apiCall('put', `/internal/order/update/bulk`, {
          status : "To Be Invoiced",
          data : toCallData
        });
      }catch(error)
      {
       throw new Error(error.message)
      }
    
      }
  }


  const bulkActionOptionsAdmin = [
    {
      label : 'To Be Invoiced',
      value : "To Be Invoiced"
    },
    {
      label: 'On Hold',
      value: 'On Hold',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
    {
      label: 'Assign',
      value: 'assign',
    },
  ];

  const defaultConfirmationProps = {
    invoiced: [
      {
        type: 'text',
        title: 'Invoice No.',
        value: '',
      },
    ],
    cancelled: [
      {
        type: 'text',
        title: 'SRT No',
        value: '',
      },
    ],
  };
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <div>
      <OrderPage
        type={orderStatus.awaitingInvoice}
        bulkActionOptions={isAdmin ? bulkActionOptionsAdmin : bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
        uploadButtonProps = {uploadButtonProps}
      />
    </div>
  );
};

export default AwaitingInvoice;
