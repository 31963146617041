import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { manageHyperLocalDeliveriesColumns } from '../orders/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import ParcelStatusPage from '../../../components/common/parcelStatusPages';

const ManageHyperLocalDelivery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [locationsData, setLocationsData] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [formState, setFormState] = useState({
    pincode: '',
    city: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const breadCrumbItems = arrayToBreadCrumbs([
    [t('Delivery Agent')],
    [t('HyperLocal Delivery')],
  ]);

  const bulkActionOptions = [
    {
      label: t('Delete'),
      value: 'delete',
    },
    {
      label: t('Update'),
      value: 'update',
    },
  ];

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const fetchLocations = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/deliveryAgent/pincode`
      );
      setLocationsData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Locations fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer(
        'danger',
        'Failed to fetch Locations',
        error.message || t('Unexpected error occurred')
      );
    }
  };
  const resetFormState = () => {
    setFormState({ pincode: '', city: '' });
    setIsEditing(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddLocation = async () => {
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(
        apiCallConsts.PUT_METHOD,
        `/internal/deliveryAgent/pincode`,
        formState
      );
      await fetchLocations();
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Location added successfully');
      resetStates();
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer(
        'danger',
        'Failed to add Location',
        error.message || t('Unexpected error occurred')
      );
    }
  };

  const handleEditLocation = async () => {
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(
        apiCallConsts.PUT_METHOD,
        `/internal/deliveryAgent/pincode`,
        {
          pincode: formState.pincode,
          city: formState.city,
        }
      );
      await fetchLocations();
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Location updated successfully');
      resetStates();
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer(
        'danger',
        'Failed to update Location',
        error.message || t('Unexpected error occurred')
      );
    }
  };

  const handleDeleteLocation = async (location) => {
    console.log('Deleting location:', location);
    if (!location) {
      messageDisplayer(
        'danger',
        'Invalid selection',
        t('No valid location selected for deletion')
      );
      return;
    }
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.DELETE_METHOD,
        `/internal/deliveryAgent/pincode/${location.objectId}`
      );
      console.log('Delete response:', response);
      await fetchLocations();
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Location deleted successfully');
      resetStatesAfterDeletion(); // Reset state after deletion only
    } catch (error) {
      console.error('Delete error:', error);
      dispatch(updateSpinnerState(false));
      messageDisplayer(
        'danger',
        'Failed to delete Location',
        error.message || t('Unexpected error occurred')
      );
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      handleEditLocation();
    } else {
      handleAddLocation();
    }
  };

  const handleEditButtonClick = (location) => {
    setSelectedLocation(location);
    setFormState({
      pincode: location.pincode,
      city: location.city,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleAddButtonClick = () => {
    resetFormState(); // Reset form state only
    setShowModal(true);
  };

  const onRowSelect = (selected, objectId) => {
    try {
      console.log('Row select triggered:', selected, objectId);
      if (selected) {
        setSelectedLocations((prev) => {
          const selectedItem = locationsData.find(
            (item) => item.objectId === objectId
          );
          if (!selectedItem) {
            throw new Error(t('Selected item not found in data'));
          }
          console.log('Adding selected item:', selectedItem);
          return [...prev, selectedItem];
        });
      } else {
        setSelectedLocations((prev) =>
          prev.filter((item) => item.objectId !== objectId)
        );
      }
      setLocationsData((prev) =>
        prev.map((item) =>
          item.objectId === objectId ? { ...item, isSelected: selected } : item
        )
      );
    } catch (error) {
      messageDisplayer(
        'danger',
        'Selection error',
        error.message || t('Unexpected error occurred during selection')
      );
    }
  };

  const handleSelectedRowsUpdate = async (actionId) => {
    try {
      console.log('Selected locations before action:', selectedLocations);
      if (actionId === 'delete') {
        if (selectedLocations.length !== 1) {
          messageDisplayer(
            'danger',
            'Select 1 location to delete',
            'Please select exactly 1 location to delete'
          );
          return;
        }
        const locationToDelete = selectedLocations[0];
        setSelectedLocation(locationToDelete);
        console.log('Selected location set for deletion:', locationToDelete);
        await handleDeleteLocation(locationToDelete);
      } else if (actionId === 'update') {
        if (selectedLocations.length !== 1) {
          messageDisplayer(
            'danger',
            'Select 1 location to update',
            'Please select exactly 1 location to update'
          );
          return;
        }
        const locationToUpdate = selectedLocations[0];
        setSelectedLocation(locationToUpdate);
        setFormState({
          pincode: locationToUpdate.pincode,
          city: locationToUpdate.city,
        });
        setIsEditing(true);
        setShowModal(true);
      }
    } catch (error) {
      messageDisplayer(
        'danger',
        'Bulk update error',
        error.message || t('Unexpected error occurred during bulk update')
      );
    }
  };

  const resetStates = () => {
    setFormState({ pincode: '', city: '' });
    setSelectedLocation(null);
    setSelectedLocations([]);
    setIsEditing(false);
    setShowModal(false);
  };

  const resetStatesAfterDeletion = () => {
    setSelectedLocation(null);
    setSelectedLocations([]);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const addLocationForm = (
    <>
      <Button
        variant="primary"
        onClick={handleAddButtonClick}
        className="mb-4 btn-lg"
      >
        {t('Add a Location')}
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? t('Edit Location') : t('Add Location')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formPincode">
              <Form.Label>{t('Pincode')}</Form.Label>
              <Form.Control
                type="text"
                name="pincode"
                value={formState.pincode}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCity">
              <Form.Label>{t('City')}</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formState.city}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {isEditing ? t('Update Location') : t('Add Location')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );

  return (
    <div>
      <ParcelStatusPage
        pageType={t('hyperLocal')}
        pageTitle={t('Hyperlocal Deliveries')}
        breadCrumbItems={breadCrumbItems}
        tableColumns={manageHyperLocalDeliveriesColumns(onRowSelect)}
        tableData={locationsData}
        showDateFilters={false}
        addLocationForm={addLocationForm}
        enableBulkActions={selectedLocations.length > 0}
        hideBulkActions={!bulkActionOptions}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
    </div>
  );
};

export default ManageHyperLocalDelivery;
