import React, { useState } from 'react';
import { Modal, Button, Form } from '@themesberg/react-bootstrap';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UnableToGenerateAWBModal = ({
  show,
  handleClose,
  onCancel,
  onSuccess,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      style={{
        textAlign: 'center',
      }}
    >
      <Modal.Body
        style={{
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationCircle}
          size={'xl'}
          style={{
            color: '#333',
            marginBottom: '10px',
          }}
        />
        <h5
          style={{
            fontWeight: '600',
            fontSize: '22px',
            color: '#333',
            marginBottom: '15px',
          }}
        >
          Unable to Generate AWB Number
        </h5>
        <p
          style={{
            fontSize: '14px',
            color: '#555',
            lineHeight: '1.6',
            marginBottom: '20px',
          }}
        >
          We are currently unable to generate the AWB Number because the PIN
          code of the order's origin does not match the supported service area.
          To proceed, please generate the AWB number manually through portals
          such as <strong>Ship Delight</strong> or <strong>Shree Maruti</strong>
          .
        </p>
        <p
          style={{
            fontSize: '12px',
            color: '#888',
            textAlign: 'left',
            marginBottom: '20px',
            maxWidth: '450px',
          }}
        >
          <strong>Note:</strong> As the AWB number cannot be generated here, we
          are unable to provide the shipping label. Please contact the HO for
          the shipping label and further assistance.
        </p>
        <Form.Group
          controlId="awbCheckbox"
          style={{
            textAlign: 'left',
            margin: '0 auto',
            display: 'inline-block',
          }}
        >
          <Form.Check
            type="checkbox"
            label="I have generated the AWB number from ShipDelight / Shree Maruti."
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            style={{
              fontSize: '14px',
              color: isChecked ? '#555' : 'red',
            }}
          />
        </Form.Group>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '50px',
            width: '80%',
          }}
        >
          <Button
            variant="link"
            className="text-gray"
            style={{ borderRadius: '0px', backgroundColor: '#F0F0F0' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ borderRadius: '0px', backgroundColor: '#444444' }}
            onClick={onSuccess}
            disabled={!isChecked}
          >
            Enter AWB Number
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnableToGenerateAWBModal;
