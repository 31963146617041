import React from 'react';
import exportJsonToExcel from '../../../../utils/newExcel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { convertdownloadDataToFlatJSON, returnDownloadableData } from '../../../../utils/excel';
// import exportJsonToExcel from "./exportJsonToExcel";

const ExportButton = ({ data, columns, fileName }) => {
  const handleExport = () => {
    const flatJSONData = data;
    exportJsonToExcel(flatJSONData, columns, fileName);
  };
  return <button onClick={handleExport} variant="white" className="btn-export"   style={{border:'none',borderRadius:'none',padding:'10px',fontWeight:'bold'}}  ><FontAwesomeIcon icon={faArrowUpFromBracket} />
  &nbsp;&nbsp;{t('Export')}</button>;
};

export default ExportButton;