import React from 'react';
import UploadFile from './UploadFile';
import CategorySafety from './CategorySafety';
import ActionWithComponents from './ActionWithComponents';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFileDataInStore } from '../../../../actions/oms/fileData';

const MoreOptionInProductsList = ({ safetyStockFileUpload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFileData = (fileData, type) => {
    if (type === 'safetyStock') {
      safetyStockFileUpload(fileData);
    } else if (type === 'manualInventory') {
      dispatch(
        setFileDataInStore({
          source: 'manualInventory',
          data: fileData,
        })
      );
      navigate('/oms/products/updateInventory');
    } else {
      dispatch(
        setFileDataInStore({
          source: 'endlessAisle',
          data: fileData,
        })
      );
      navigate('/oms/products/updateInventory');
    }
  };

  return (
    <ActionWithComponents>
      <CategorySafety />
      <UploadFile
        uploadedData={(fileData) => handleFileData(fileData, 'manualInventory')}
        sampleFileUrl={
          'https://vs-system-templates.s3.ap-south-1.amazonaws.com/inventoryStoreLevelTemplate.xlsx'
        }
        buttonText="Upload Manual Inventory"
        buttonSize="md"
      />
      <UploadFile
        uploadedData={(fileData) => handleFileData(fileData, 'endlessAisle')}
        sampleFileUrl={
          'https://vs-system-templates.s3.ap-south-1.amazonaws.com/inventoryStoreLevelTemplate.xlsx'
        }
        buttonText="Upload Endless Inventory"
        buttonSize="md"
      />
      <UploadFile
        uploadedData={(fileData) => handleFileData(fileData, 'safetyStock')}
        sampleFileUrl={
          'https://vs-system-templates.s3.ap-south-1.amazonaws.com/ProductSafetyStockTemplate.xlsx'
        }
        buttonText="Upload Safety Stock"
        buttonSize="md"
      />
    </ActionWithComponents>
  );
};

export default MoreOptionInProductsList;
