import {
  faBoltLightning,
  faDesktop,
  faHighlighter,
  faInfinity,
  faInfoCircle,
  faLaptop,
  faPen,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import ActionButtonWithIcons from '../../../components/common/ActionButtonWithIcons';
import {
  faCalendar,
  faClipboard,
  faEye,
  faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import StatusCard from '../interBranchTransfers/StatusCard';
import { getTableRowCheckBox } from '../../../utils/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { StyledSearchBar } from '../../trips/styles';
import { rolesConstants } from '../../../constants/roles';
import { hasRole } from '../../../utils/roles';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { returnUserReadableDate } from '../../../utils/datetime';
import { ErrorMessage } from '../../scheme-management/Components/StyledComponents';

export const productsOptions = {
  view: 'View',
  markEndlessAisle: 'Mark as Endless Aisle',
  markManualInventory: 'Mark as Manual Inventory',
  updateInventory: 'Update Inventory',
  updateSafetyStock: 'Update Saftey Stock',
  markSystemInventory: 'Mark as System Inventory',
  markVirtual: 'Mark as Virtual',
};

export const getActionOptions = (
  isEndless,
  isManualInventory,
  isVirtual,
  roles
) => {
  const options = [
    { icon: faEye, label: productsOptions.view },
    { icon: faPen, label: productsOptions.updateSafetyStock },
  ];

  if (!isVirtual) {
    options.push({ icon: faBoltLightning, label: productsOptions.markVirtual });
  }

  if (isEndless) {
    options.push({ icon: faPlus, label: productsOptions.updateInventory });
    if (hasRole(roles, [rolesConstants.ADMIN, rolesConstants.SUPER_ADMIN])) {
      if (!isManualInventory) {
        options.push({
          icon: faPenToSquare,
          label: productsOptions.markManualInventory,
        });
      } else {
        options.push({
          icon: faLaptop,
          label: productsOptions.markSystemInventory,
        });
        // options.push({ icon: faPlus, label: productsOptions.updateInventory });
      }
    }
  } else {
    if (hasRole(roles, [rolesConstants.ADMIN, rolesConstants.SUPER_ADMIN])) {
      if (!isManualInventory) {
        options.push({
          icon: faPenToSquare,
          label: productsOptions.markManualInventory,
        });
      } else {
        options.push({
          icon: faLaptop,
          label: productsOptions.markSystemInventory,
        });
        options.push({ icon: faPlus, label: productsOptions.updateInventory });
      }
    }
    options.push({
      icon: faHighlighter,
      label: productsOptions.markEndlessAisle,
    });
  }
  return options;
};

export const productListColumns = (onActionClick, onRowSelect, roles) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'select',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return (
          <div>
            {getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)}
          </div>
        );
      },
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'productTitle',
      text: 'name',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return (
          <>
            <div
              className="d-block"
              style={{ width: '16.25rem', whiteSpace: 'wrap' }}
            >
              {cell}
            </div>
            {(row?.isEndlessAisle ||
              row?.isManualInventory ||
              row?.isPrebookProduct === '1' ||
              row?.size === 'Digital / Serviceable') && (
              <div className="d-inline-flex gap-2">
                {row?.isEndlessAisle && (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 300, hide: 300 }}
                    overlay={
                      <Tooltip id={`tooltip-${row.objectId}`}>
                        <FontAwesomeIcon size="xs" icon={faInfinity} />{' '}
                        &nbsp;&nbsp; Marked as Endless Aisle
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        width: '2.125rem',
                        height: '2.125rem',
                        borderRadius: '50%',
                        border: '1px solid rgba(200, 200, 200, 0.8)',
                      }}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        icon={faInfinity}
                        style={{
                          color: 'rgba(100, 100, 100, 1)',
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                )}
                {row?.isManualInventory && (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 300, hide: 300 }}
                    overlay={
                      <Tooltip id={`tooltip-${row.objectId}`}>
                        <FontAwesomeIcon size="xs" icon={faClipboard} />{' '}
                        &nbsp;&nbsp; Product marked as Manual Inventory.
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        width: '2.125rem',
                        height: '2.125rem',
                        borderRadius: '50%',
                        border: '1px solid rgba(200, 200, 200, 0.8)',
                      }}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        icon={faClipboard}
                        style={{
                          color: 'rgba(100, 100, 100, 1)',
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                )}
                {row?.isPrebookProduct === '1' && (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 300, hide: 300 }}
                    overlay={
                      <Tooltip id={`tooltip-${row.objectId}`}>
                        <FontAwesomeIcon size="xs" icon={faCalendar} />{' '}
                        &nbsp;&nbsp;{' '}
                        {`This product is pre-booked and will be available for delivery after ${returnUserReadableDate(
                          row?.prebookLaunchDate
                        )}.`}
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        width: '2.125rem',
                        height: '2.125rem',
                        borderRadius: '50%',
                        border: '1px solid rgba(200, 200, 200, 0.8)',
                      }}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        icon={faCalendar}
                        style={{
                          color: 'rgba(100, 100, 100, 1)',
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                )}
                {row?.size === 'Digital / Serviceable' && (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 300, hide: 300 }}
                    overlay={
                      <Tooltip id={`tooltip-${row.objectId}`}>
                        <FontAwesomeIcon size="xs" icon={faDesktop} />{' '}
                        &nbsp;&nbsp;{' '}
                        {`This is a virtual product. It can be accessed online or downloaded after purchase.`}
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        width: '2.125rem',
                        height: '2.125rem',
                        borderRadius: '50%',
                        border: '1px solid rgba(200, 200, 200, 0.8)',
                      }}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        icon={faDesktop}
                        style={{
                          color: 'rgba(100, 100, 100, 1)',
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'brand',
      text: 'company',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'l3',
      text: 'category',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'l4',
      text: 'subcategory',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'size',
      text: 'size',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'safetyStockLimit',
      text: 'safetyStockLimit',
      headerClasses: 'rounded-0 p-3 h-auto',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'Action',
      text: 'action',
      headerClasses: 'rounded-0 p-3 h-auto',
      style: { textAlign: 'center' },
      formatter: (cell, row) => {
        return (
          <>
            <ActionButtonWithIcons
              options={getActionOptions(
                row?.isEndlessAisle,
                row?.isManualInventory,
                row?.size === 'Digital / Serviceable',
                roles
              )}
              onActionClick={(option) => onActionClick(option, row?.vanNo)}
            />
          </>
        );
      },
    },
  ];
  return columns;
};

export const InventoryColumns = () => {
  const columns = [
    {
      dataField: 'storeCode',
      text: 'storeCode',
      headerClasses: 'mx-0',
      style: { width: '200px' },
      //   classes: 'p-5',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'safetyStock',
      text: 'safety',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inProcess',
      text: 'inProcess',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inBlocked',
      text: 'inBlocked',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'onHold',
      text: 'onHold',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'available',
      text: 'available',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell}</div>;
      },
    },
    {
      dataField: 'totalStock',
      text: 'total',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{row?.totalStock}</div>;
      },
    },
  ];
  return columns;
};

export const updateInventoryColumns = (
  handleTableChange,
  citiesAndStoreMap = {},
  errors
) => {
  const columns = [
    {
      dataField: 'cityName',
      text: 'city Name',
      headerClasses: 'mx-0',
      style: { width: '200px' },
      //   classes: 'p-5',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'vanNo',
      text: 'van no',
      headerClasses: 'mx-0',
      style: { width: '200px' },
      //   classes: 'p-5',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'storeCode',
      text: 'Store',
      formatter: (cell, row) => {
        const options = arrayToDropdownOptions([
          ...citiesAndStoreMap[row?.cityName],
        ]);
        return (
          <EditableDropDown
            key={row?.objectId}
            id={`storeCode-${row.objectId}`}
            onChange={(e) => handleTableChange(e, 'storeCode', row.objectId)}
            width={'12rem'}
            value={cell}
            dropdownBackgroundColor={'light'}
            options={options}
          />
        );
      },
    },
    {
      dataField: 'deliveryNote',
      text: 'delivery label',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return (
          <div style={{ width: '100%' }}>
            <Form.Control
              width={'100%'}
              type="text"
              key={row?.objectId}
              id={`deliveryNote-${row.objectId}`}
              style={{ height: '2.5rem' }}
              className="rounded-0 bg-light"
              value={cell}
              onChange={(e) =>
                handleTableChange(e, 'deliveryNote', row.objectId)
              }
            />
            {errors[`deliveryNote-${row.objectId}`] && (
              <ErrorMessage className="text-danger">
                {errors[`deliveryNote-${row.objectId}`]}
              </ErrorMessage>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'edd',
      text: 'Delivery Days',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return (
          <Form.Control
            width={'100%'}
            type="number"
            key={row?.objectId}
            id={`edd-${row.objectId}`}
            style={{ height: '2.5rem' }}
            className="rounded-0 bg-light"
            value={cell}
            onChange={(e) => handleTableChange(e, 'edd', row.objectId)}
          />
        );
      },
    },
    {
      dataField: 'available',
      text: 'available Inventory',
      headerClasses: 'text-center',
      headerFormatter: () => {
        return (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 300, hide: 300 }}
            overlay={
              <Tooltip id={`tooltip-availableInventory`}>
                NOTE: Available inventory excludes products/orders currently in
                the process of delivery.
              </Tooltip>
            }
          >
            <div>
              <span>Available Inventory</span>{' '}
              <FontAwesomeIcon
                size="sm"
                icon={faInfoCircle}
                style={{
                  color: 'rgba(68, 68, 68, 1)',
                }}
              />
            </div>
          </OverlayTrigger>
        );
      },
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'safetyStock',
      text: 'Available Safety Stock',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'Uavailable',
      text: 'Update Inventory',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return (
          <Form.Group>
            <Form.Control
              width={'100%'}
              key={row?.objectId}
              id={`Uavailable-${row.objectId}`}
              type="number"
              style={{ height: '2.5rem' }}
              className="rounded-0 bg-light"
              value={cell}
              onChange={(e) => handleTableChange(e, 'Uavailable', row.objectId)}
            />
          </Form.Group>
        );
      },
    },
    {
      dataField: 'UsafetyStock',
      text: 'Update Safety Stock',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return (
          <Form.Control
            width={'100%'}
            type="number"
            key={row?.objectId}
            id={`UsafetyStock-${row.objectId}`}
            className="rounded-0 bg-light"
            style={{ height: '2.5rem' }}
            value={cell}
            onChange={(e) => handleTableChange(e, 'UsafetyStock', row.objectId)}
          />
        );
      },
    },
  ];
  return columns;
};

export const citiesToRemove = new Set([
  'THANE',
  'NAVI MUMBAI',
  'PALGHAR',
  'DELHI',
  'INDIRAPURAM',
  'GUNTUR',
]);

export const summaryModalColumns = () => {
  const columns = [
    {
      dataField: 'cityName',
      text: 'City Name',
    },
    {
      dataField: 'storeCode',
      text: 'store code',
    },
    {
      dataField: 'deliveryNote',
      text: 'delivery Note',
    },
    {
      dataField: 'edd',
      text: 'Delivery Days',
    },
    {
      dataField: 'available',
      text: 'Previous Inventory',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'Uavailable',
      text: 'Updated Inventory',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'safetyStock',
      text: 'Previous Safety Stock',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'UsafetyStock',
      text: 'Updated Safety stock',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
  ];
  return columns;
};

export const safetySummaryModalColumns = (isFileData) => {
  const columns = [
    {
      dataField: 'safetyStockLimit',
      text: 'Current Safety Stock',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'newSafetyStockLimit',
      text: 'New Safety Stock',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'change',
      text: 'Change in Safety Stock',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
  ];
  if (isFileData) {
    columns.splice(0, 0, {
      dataField: 'vanNo',
      text: 'van no',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || ''}</div>;
      },
    });
  } else {
    columns.splice(0, 0, {
      dataField: 'available',
      text: 'Current Stock',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    });
  }
  return columns;
};
