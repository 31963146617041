import React from 'react';
import { createBrowserRouter, Router } from 'react-router-dom';
import RouteWithSidebar from '../components/common/withSidebar';
import Login from '../pages/Login';
import PlanningPage from '../pages/PlanningPage';
import AddWarehouse from '../pages/warehouse/addWarehouse';
import AddWarehouseUser from '../pages/incharge/addIncharge';
import { Routes } from './routes';
import TemporaryTrips from '../pages/trips/temporaryTrips';
import TemporaryTripDetails from '../pages/trips/temporaryTripDetails';
import PrintTripSheet from '../pages/trips/printTripSheet';
import TripsList from '../pages/trips/tripsList';
import ReceiveTemposheet from '../pages/trips/receiveTemposheet';
import ActiveTripDetails from '../pages/trips/tripDetail';
import PricingTrackerHome from '../pages/pricingTracker/home';
import ReturnOKList from '../pages/returns/returnok.js';
import ReturnRegisterList from '../pages/returns/returnRegister.js';
import ReturnScheduledList from '../pages/returns/returnScheduledList.js';
import BuybackPendingList from '../pages/buyback/pending';
import BuybackReceivedList from '../pages/buyback/received';
import BuybackRegisterList from '../pages/buyback/register';
import PrintChallan from '../pages/print/challan';
import BuybackScheduledList from '../pages/buyback/scheduled.js';
import BuybackCashRegister from '../pages/buyback/cashRegister';
import PickupPendingList from '../pages/defective/pickupPendingList';
import PickupReceivedList from '../pages/defective/pickupReceivedList';
import DefectivePickupRegisterList from '../pages/defective/pickupRegister';
import DefectivePickupScheduledList from '../pages/defective/pickupScheduled';
import ReplacementPendingPickupList from '../pages/replacement/replacementPending';
import ReplacementPickupRegisterList from '../pages/replacement/replacementRegister';
import ReplacementScheduledList from '../pages/replacement/replacementScheduled';
import VehicleVendorList from '../pages/vendors/list';
import AddVendor from '../pages/vendors/add';
import VehiclesList from '../pages/vehicles/list';
import AddVehicles from '../pages/vehicles/add';
import AddNewRequistion from '../pages/requisitions/add';
import RequisitionsSentPage from '../pages/requisitions/sent';
import RequisitionsReceived from '../pages/requisitions/received';
import PrintRequisition from '../pages/print/requisition';
import DeliveryDataComponent from '../pages/analysis/delivery-data';
import DeliveryAnalysisComponent from '../pages/analysis/delivery-analysis';
import PriorityDeliveryComponent from '../pages/analysis/priority-delivery';
import BranchPickupComponent from '../pages/analysis/branch-pickup';
import PrintEWayBill from '../pages/print/ewaybill';
import DailyDeliveryReport from '../pages/reports/dailyDelivery';
import LoadersListAndUpload from '../pages/loaders/list';
import UpdateTemposheetDateAndVehicle from '../pages/admin/temposheet';
import AddProductToPIM from '../pages/admin/product/add';
import ModelChangeRequestItems from '../pages/modelChange/list';
import ModelChangeScheduledItems from '../pages/modelChange/scheduled';
import ModelChangeRegisterItems from '../pages/modelChange/register';
import AddCustomerCareEmployee from '../pages/customercare/add';
import AddCustomerCareManager from '../pages/customercare/manager/add';
import AddAdminManager from '../pages/admin/user/add';
import VehicleAttendance from '../pages/reports/vehicleAttendance';
import MonthlyDelivery from '../pages/reports/monthlyDelivery';
import BranchBreakdownRequisition from '../pages/requisitions/branchBreakdown';
import RequisitionCreated from '../pages/requisitions/created';
import RequisitionPendingApproval from '../pages/requisitions/pendingApproval';
import AddLoaders from '../pages/loaders/add';
import InchargesList from '../pages/incharge/list';
import PendingDeliveryReport from '../pages/executive-summary/pending-delivery';
import TeamLeadAdd from '../pages/wms/teamlead/add.js';
import TeamLeadList from '../pages/wms/teamlead/list.js';
// import WMSPurchaseTarget from "../pages/wms/purchase/target.js";
import WMSPurchaseTarget from '../pages/wms/purchase/target.js';
import StoresList from '../pages/oms/stores/storesList.js';
import AddSchemeUsers from '../pages/scheme-management/Components/AddUsers/add.js';
import AddScheme from '../pages/scheme-management/Components/Scheme/AddScheme.js';
import DraftSchemes from '../pages/scheme-management/Components/Scheme/DraftSchemes.js';
import ActiveSchemes from '../pages/scheme-management/Components/Scheme/ActiveSchemes.js';
import RejectedSchemes from '../pages/scheme-management/Components/Scheme/RejectedSchemes.js';
import ClosedSchemes from '../pages/scheme-management/Components/Scheme/ClosedSchemes.js';
import CancelledTargets from '../pages/wms/targets/cancelled.js';
import CompletedTargets from '../pages/wms/targets/completed.js';
import ScannedTargets from '../pages/wms/targets/scanned.js';
import AssignedTargets from '../pages/wms/targets/assigned.js';
import ProductInventory from '../pages/oms/inventory/productInventory.js';
import AssignedOrders from '../pages/oms/orders/AssignedOrders.js';
import Accepted from '../pages/oms/orders/Accepted.js';
import ReadyForPickup from '../pages/oms/orders/ReadyForPickup.js';
import Rejected from '../pages/oms/orders/Rejected.js';
import Cancelled from '../pages/oms/orders/Cancelled.js';
import SchemeAnalysisComponent from '../pages/scheme-management/Components/IndividualScheme.js/showDetails.js';
import SMSUserList from '../pages/scheme-management/Components/AddUsers/SMSUserList.js';
import OrderDetails from '../pages/oms/orders/components/OrderDetails.js';
import AwaitingInvoice from '../pages/oms/orders/AwaitingInvoice.js';
import Invoiced from '../pages/oms/orders/Invoiced.js';
import PickedUp from '../pages/oms/orders/PickedUp.js';
import PendingAction from '../pages/oms/orders/PendingAction.js';
import TestServiceability from '../pages/oms/serviceability/TestServiceability.js';
import AllDeliveries from '../pages/oms/deliveryIntegrations/AllDeliveries.js';
import DetailsPage from '../pages/oms/deliveryIntegrations/DetailsPage.js';
import OMSUsersList from '../pages/oms/users/OMSUsersList.js';
import Delivered from '../pages/oms/orders/Delivered.js';
import AutoRejected from '../pages/oms/orders/AutoRejected.js';
import ViewSchemeDetails from '../pages/scheme-management/Components/IndividualScheme.js/ViewSchemeDetails.js';
import ReturnAccepted from '../pages/oms/returns/ReturnAccepted.js';
import Picked from '../pages/oms/returns/Picked.js';
import QcCheck from '../pages/oms/returns/QcCheck.js';
import PreBooked from '../pages/oms/orders/PreBooked.js';
import PreRegistered from '../pages/oms/orders/PreRegistered.js';
import ProductsRelease from '../pages/oms/productsRelease/ProductsRelease.js';
import Requested from '../pages/oms/returns/Requested.js';
import Received from '../pages/oms/returns/Received.js';
import RefundInitiated from '../pages/oms/returns/RefundInitiated.js';
import Refunded from '../pages/oms/returns/Refunded.js';
import AddDeliveryAgent from '../pages/oms/users/AddDeliveryAgent.js';
import DeliveryAgentsList from '../pages/oms/users/DeliveryAgentsList.js';
import EntryList from '../pages/securityGuard/EntryList.js';
import QCFailed from '../pages/oms/orders/QCFailed.js';
import PendingTargets from '../pages/wms/targets/pending.js';
import AddSecurityGuardPage from '../pages/securityGuard/components/addSecurityGuardPage.js';
import Roles from '../pages/admin/user/Roles.js';
import FilteredOrders from '../pages/oms/orders/FilteredOrders.js';
import Allorders from '../pages/oms/orders/AllOrders.js';
import ManualAssign from '../pages/oms/deliveryAgent/ManualAssign.js';
import getHyperLocalOrders from '../pages/oms/deliveryAgent/getHyperLocalOrders.js';
import ManageHyperLocalDelivery from '../pages/oms/deliveryAgent/manageHyperLocal.js';
import AddUser from '../pages/oms/users/AddUser.js';
import AwaitingInvoiceRegion from '../pages/oms/regionOrders/AwaitingInvoiceRegion.js';
import Serviceability from '../pages/oms/serviceability/Serviceability.js';
import PaymentChallans from '../pages/payments/PaymentChallans.js';
import CreatePaymentVoucher from '../pages/payments/CreatePaymentVoucher.js';
import SentVouchers from '../pages/payments/SentVouchers.js';
import ReceivedVouchers from '../pages/payments/ReceivedVouchers.js';
import VoucherPrint from '../pages/print/PaymentVoucher.js';
import HomePage from '../pages/homePage/HomePage.js';
import Pending from '../pages/oms/customerPickup/Pending.js';
import Completed from '../pages/oms/customerPickup/Completed.js';
import HomeInventory from '../pages/oms/inventory/HomeInventory.js';
import InventorySync from '../pages/oms/inventory/InventorySync.js';
import OnHold from '../pages/oms/orders/OnHold.js';
import OutForDelivery from '../pages/oms/orders/OutForDelivery.js';
import validation from '../pages/oms/gst/validation.js';
import CreateRequest from '../pages/oms/interBranchTransfers/CreateRequest.js';
import IBTReceived from '../pages/oms/interBranchTransfers/IBTReceived.js';
import IBTSent from '../pages/oms/interBranchTransfers/IBTSent.js';
import UnpaidTripPayments from '../pages/tripPayments/unpaid.js';
import PaidTripPayments from '../pages/tripPayments/paid.js';
import BikerAnalysis from '../pages/oms/deliveryAgent/BikerAnalysis.js';
import Analytics from '../pages/oms/dashboard/Analytics.js';
import Authorization from '../pages/oms/payment/PaymentAuth.js';
import AddSchemeRedesign from '../pages/scheme-management/Components/SchemRedesign/AddSchemeRedesign.js';
import RedesignActiveSchemes from '../pages/scheme-management/Components/SchemRedesign/RedesignActiveSchemes.js';
import RedesignClosedSchemes from '../pages/scheme-management/Components/SchemRedesign/RedesignClosedSchemes.js';
import RedesignDraftSchemes from '../pages/scheme-management/Components/SchemRedesign/RedesignDraftSchemes.js';
import RedesignRejectedSchemes from '../pages/scheme-management/Components/SchemRedesign/RedesignRejectedSchemes.js';
import EditScheme from '../pages/scheme-management/Components/SchemRedesign/EditScheme.js';
import RedesignSchemeAnalysisComponent from '../pages/scheme-management/Components/IndividualScheme.js/SchemeAnalysisComponent.js';
import AddProductsinScheme from '../pages/scheme-management/Components/AddProduct/AddProductsinScheme.js';
import SchemeDashboard from '../pages/scheme-management/Components/Dashboard/SchemeDashboard.js';
import ProductsList from '../pages/oms/products/ProductsList.js';
import ProductDetails from '../pages/oms/products/ProductDetails.js';
import EndlessPending from '../pages/oms/endlessAisleOrders/EndlessPending.js';
import EndlessProcessing from '../pages/oms/endlessAisleOrders/EndlessProcessing.js';
import UpdateInventory from '../pages/oms/products/UpdateInventory.js';
import PerDeliveryRateEdit from '../pages/tripPayments/PerDeliveryRateEdit.js';
import PaymentReports from '../pages/reports/paymentsReport.js';
import ManualInvoice from '../pages/oms/orders/ManualInvoice.js';
import ManageServiceability from '../pages/oms/serviceability/ManualServiceability.js';
import OutOfStock from '../pages/oms/orders/OutOfStock.js';
import CourierPickup from '../pages/oms/orders/CourierPickup.js';
import HyperLocalPickup from '../pages/oms/orders/HyperLocalPickup.js';
import CustomerPickup from '../pages/oms/orders/CustomerPickup.js';
import DelayedOrdersAnalytics from '../pages/oms/dashboard/DelayedOrdersAnalytics.js';

const router = createBrowserRouter([
  {
    path: Routes.HomePage.path,
    element: <RouteWithSidebar component={HomePage} />,
  },
  {
    path: Routes.PlanningPage.path,
    element: <RouteWithSidebar component={PlanningPage} />,
  },
  {
    path: Routes.Login.path,
    element: <Login />,
  },
  {
    path: Routes.Warehouse.Add.path,
    element: <RouteWithSidebar component={AddWarehouse} />,
  },
  {
    path: Routes.Incharge.Add.path,
    element: <RouteWithSidebar component={AddWarehouseUser} />,
  },
  {
    path: Routes.Incharge.List.path,
    element: <RouteWithSidebar component={InchargesList} />,
  },
  {
    path: Routes.Trips.Temporary.path,
    element: <RouteWithSidebar component={TemporaryTrips} />,
  },
  {
    path: Routes.Trips.TemporaryDetail.path,
    element: <RouteWithSidebar component={TemporaryTripDetails} />,
  },
  {
    path: Routes.Trips.PrintSheet.path,
    element: <PrintTripSheet />,
  },
  {
    path: Routes.Trips.TripsList.path,
    element: <RouteWithSidebar component={TripsList} />,
  },
  {
    path: Routes.Trips.ReceiveStock.path,
    element: (
      <RouteWithSidebar
        component={ReceiveTemposheet}
        isTemposheetReceived={false}
      />
    ),
  },
  {
    path: Routes.Trips.ReceiveTemposheet.path,
    element: (
      <RouteWithSidebar component={ReceiveTemposheet} isTemposheetReceived />
    ),
  },
  {
    path: Routes.Trips.TripDetail.path,
    element: <RouteWithSidebar component={ActiveTripDetails} />,
  },
  {
    path: Routes.PricingTracker.Home.path,
    element: <RouteWithSidebar component={PricingTrackerHome} />,
  },
  {
    path: Routes.Returns.ReturnOK.path,
    element: <RouteWithSidebar component={ReturnOKList} />,
  },
  {
    path: Routes.Returns.ReturnRegister.path,
    element: <RouteWithSidebar component={ReturnRegisterList} />,
  },
  {
    path: Routes.Returns.ReturnScheduled.path,
    element: <RouteWithSidebar component={ReturnScheduledList} />,
  },
  {
    path: Routes.BuyBack.Pending.path,
    element: <RouteWithSidebar component={BuybackPendingList} />,
  },
  {
    path: Routes.BuyBack.Received.path,
    element: <RouteWithSidebar component={BuybackReceivedList} />,
  },
  {
    path: Routes.BuyBack.Register.path,
    element: <RouteWithSidebar component={BuybackRegisterList} />,
  },
  {
    path: Routes.BuyBack.Scheduled.path,
    element: <RouteWithSidebar component={BuybackScheduledList} />,
  },
  {
    path: Routes.BuyBack.CashRegister.path,
    element: <RouteWithSidebar component={BuybackCashRegister} />,
  },
  {
    path: Routes.Defective.PickupPending.path,
    element: <RouteWithSidebar component={PickupPendingList} />,
  },
  {
    path: Routes.Defective.PickupReceived.path,
    element: <RouteWithSidebar component={PickupReceivedList} />,
  },
  {
    path: Routes.Defective.Register.path,
    element: <RouteWithSidebar component={DefectivePickupRegisterList} />,
  },
  {
    path: Routes.Defective.PickupScheduled.path,
    element: <RouteWithSidebar component={DefectivePickupScheduledList} />,
  },
  {
    path: Routes.Replacement.PickupPending.path,
    element: <RouteWithSidebar component={ReplacementPendingPickupList} />,
  },
  {
    path: Routes.Replacement.Register.path,
    element: <RouteWithSidebar component={ReplacementPickupRegisterList} />,
  },
  {
    path: Routes.Replacement.Scheduled.path,
    element: <RouteWithSidebar component={ReplacementScheduledList} />,
  },
  {
    path: Routes.Vendors.List.path,
    element: <RouteWithSidebar component={VehicleVendorList} />,
  },
  {
    path: Routes.Vendors.Add.path,
    element: <RouteWithSidebar component={AddVendor} />,
  },
  {
    path: Routes.Vehicles.List.path,
    element: <RouteWithSidebar component={VehiclesList} />,
  },
  {
    path: Routes.Vehicles.Add.path,
    element: <RouteWithSidebar component={AddVehicles} />,
  },
  {
    path: Routes.Requisitions.Add.path,
    element: <RouteWithSidebar component={AddNewRequistion} />,
  },
  {
    path: Routes.Requisitions.Sent.path,
    element: <RouteWithSidebar component={RequisitionsSentPage} />,
  },
  {
    path: Routes.Requisitions.Received.path,
    element: <RouteWithSidebar component={RequisitionsReceived} />,
  },
  {
    path: Routes.Requisitions.Print.path,
    element: <PrintRequisition />,
  },
  {
    path: Routes.Challan.Print.path,
    element: <PrintChallan />,
  },
  {
    path: Routes.Analysis.DeliveryData.path,
    element: <RouteWithSidebar component={DeliveryDataComponent} />,
  },
  {
    path: Routes.Analysis.DeliveryAnalysis.path,
    element: <RouteWithSidebar component={DeliveryAnalysisComponent} />,
  },
  {
    path: Routes.Analysis.PriorityDelivery.path,
    element: <RouteWithSidebar component={PriorityDeliveryComponent} />,
  },
  {
    path: Routes.Analysis.BranchPickup.path,
    element: <RouteWithSidebar component={BranchPickupComponent} />,
  },
  {
    path: Routes.EWB.Print.path,
    element: <PrintEWayBill />,
  },
  {
    path: Routes.Reports.DailyDelivery.path,
    element: <RouteWithSidebar component={DailyDeliveryReport} />,
  },
  {
    path: Routes.Loader.List.path,
    element: <RouteWithSidebar component={LoadersListAndUpload} />,
  },
  {
    path: Routes.Loader.Add.path,
    element: <RouteWithSidebar component={AddLoaders} />,
  },
  {
    path: Routes.Admin.Temposheet.path,
    element: <RouteWithSidebar component={UpdateTemposheetDateAndVehicle} />,
  },
  {
    path: Routes.Admin.Product.Add.path,
    element: <RouteWithSidebar component={AddProductToPIM} />,
  },
  {
    path: Routes.ModelChange.Pending.path,
    element: <RouteWithSidebar component={ModelChangeRequestItems} />,
  },
  {
    path: Routes.ModelChange.Scheduled.path,
    element: <RouteWithSidebar component={ModelChangeScheduledItems} />,
  },
  {
    path: Routes.ModelChange.Register.path,
    element: <RouteWithSidebar component={ModelChangeRegisterItems} />,
  },
  {
    path: Routes.CustomerCare.Add.path,
    element: <RouteWithSidebar component={AddCustomerCareEmployee} />,
  },
  {
    path: Routes.CustomerCare.Manager.Add.path,
    element: <RouteWithSidebar component={AddCustomerCareManager} />,
  },
  {
    path: Routes.Admin.User.Add.path,
    element: <RouteWithSidebar component={AddAdminManager} />,
  },
  {
    path: Routes.Reports.VehicleAttendance.path,
    element: <RouteWithSidebar component={VehicleAttendance} />,
  },
  {
    path: Routes.Reports.MonthlyDelivery.path,
    element: <RouteWithSidebar component={MonthlyDelivery} />,
  },
  {
    path: Routes.Requisitions.BranchBreakdown.path,
    element: <RouteWithSidebar component={BranchBreakdownRequisition} />,
  },
  {
    path: Routes.Requisitions.Created.path,
    element: <RouteWithSidebar component={RequisitionCreated} />,
  },
  {
    path: Routes.Requisitions.PendingApproval.path,
    element: <RouteWithSidebar component={RequisitionPendingApproval} />,
  },
  {
    path: Routes.ExecutiveReports.PendingDelivery.path,
    element: <RouteWithSidebar component={PendingDeliveryReport} />,
  },
  {
    path: Routes.TeamLead.List.path,
    element: <RouteWithSidebar component={TeamLeadList} />,
  },
  {
    path: Routes.TeamLead.Add.path,
    element: <RouteWithSidebar component={TeamLeadAdd} />,
  },
  {
    path: Routes.WMS.Target.Assigned.path,
    element: <RouteWithSidebar component={AssignedTargets} />,
  },
  {
    path: Routes.WMS.Target.Scanned.path,
    element: <RouteWithSidebar component={ScannedTargets} />,
  },
  {
    path: Routes.WMS.Target.Completed.path,
    element: <RouteWithSidebar component={CompletedTargets} />,
  },
  {
    path: Routes.WMS.Target.Cancelled.path,
    element: <RouteWithSidebar component={CancelledTargets} />,
  },
  {
    path: Routes.WMS.Target.Pending.path,
    element: <RouteWithSidebar component={PendingTargets} />,
  },
  {
    path: Routes.SMS.Target.AddUser.path,
    element: <RouteWithSidebar component={AddSchemeUsers} />,
  },
  {
    path: Routes.SMS.Target.AddScheme.path,
    element: <RouteWithSidebar component={AddScheme} />,
  },
  {
    path: Routes.SMS.Target.DraftSchemes.path,
    element: <RouteWithSidebar component={DraftSchemes} />,
  },
  {
    path: Routes.SMS.Target.ActiveSchemes.path,
    element: <RouteWithSidebar component={ActiveSchemes} />,
  },
  {
    path: Routes.SMS.Target.RejectedSchemes.path,
    element: <RouteWithSidebar component={RejectedSchemes} />,
  },
  {
    path: Routes.SMS.Target.ClosedSchemes.path,
    element: <RouteWithSidebar component={ClosedSchemes} />,
  },
  {
    path: Routes.WMS.Target.Assign.path,
    element: <RouteWithSidebar component={WMSPurchaseTarget} />,
  },
  {
    path: Routes.WMS.Target.Assigned.path,
    element: <RouteWithSidebar component={AssignedTargets} />,
  },
  {
    path: Routes.OMS.Store.Stores.path,
    element: <RouteWithSidebar component={StoresList} />,
  },
  {
    path: Routes.OMS.Products.Inventory.path,
    element: <RouteWithSidebar component={HomeInventory} />,
  },
  {
    path: Routes.OMS.Orders.Assigned.path,
    element: <RouteWithSidebar component={AssignedOrders} />,
  },
  {
    path: Routes.OMS.Orders.Accepted.path,
    element: <RouteWithSidebar component={Accepted} />,
  },
  {
    path: Routes.OMS.Orders.Awaiting.path,
    element: <RouteWithSidebar component={AwaitingInvoice} />,
  },
  {
    path: Routes.OMS.Orders.Invoice.path,
    element: <RouteWithSidebar component={Invoiced} />,
  },
  {
    path: Routes.OMS.Orders.ReadyForPickup.path,
    element: <RouteWithSidebar component={ReadyForPickup} />,
  },
  {
    path: Routes.OMS.Orders.Picked.path,
    element: <RouteWithSidebar component={PickedUp} />,
  },
  {
    path: Routes.OMS.Orders.Rejected.path,
    element: <RouteWithSidebar component={Rejected} />,
  },
  {
    path: Routes.OMS.Orders.Cancelled.path,
    element: <RouteWithSidebar component={Cancelled} />,
  },
  {
    path: Routes.OMS.Orders.OutOfStock.path,
    element: <RouteWithSidebar component={OutOfStock} />,
  },

  {
    path: Routes.OMS.Orders.Details.path,
    element: <RouteWithSidebar component={OrderDetails} />,
  },
  {
    path: Routes.OMS.Orders.PendingAction.path,
    element: <RouteWithSidebar component={PendingAction} />,
  },
  {
    path: Routes.OMS.Users.List.path,
    element: <RouteWithSidebar component={OMSUsersList} />,
  },
  {
    path: Routes.OMS.Users.AddDeliveryAgent.path,
    element: <RouteWithSidebar component={AddDeliveryAgent} />,
  },
  {
    path: Routes.OMS.Users.DeliveryAgentsList.path,
    element: <RouteWithSidebar component={DeliveryAgentsList} />,
  },
  {
    path: Routes.OMS.Serviceability.Test.path,
    element: <RouteWithSidebar component={Serviceability} />,
  },
  {
    path: Routes.OMS.DeliveryIntegrations.List.path,
    element: <RouteWithSidebar component={AllDeliveries} />,
  },
  {
    path: Routes.OMS.DeliveryIntegrations.Details.path,
    element: <RouteWithSidebar component={DetailsPage} />,
  },
  {
    path: Routes.OMS.Orders.Delivered.path,
    element: <RouteWithSidebar component={Delivered} />,
  },
  {
    path: Routes.OMS.Orders.ManualInvoice.path,
    element: <RouteWithSidebar component={ManualInvoice} />,
  },
  {
    path: Routes.OMS.Orders.AutoRejected.path,
    element: <RouteWithSidebar component={AutoRejected} />,
  },
  {
    path: Routes.OMS.Serviceability.Manual.path,
    element: <RouteWithSidebar component={ManageServiceability} />,
  },
  {
    path: Routes.OMS.Returns.Requested.path,
    element: <RouteWithSidebar component={Requested} />,
  },
  {
    path: Routes.OMS.Returns.Accepted.path,
    element: <RouteWithSidebar component={ReturnAccepted} />,
  },
  {
    path: Routes.OMS.Returns.Picked.path,
    element: <RouteWithSidebar component={Picked} />,
  },
  {
    path: Routes.OMS.Returns.Received.path,
    element: <RouteWithSidebar component={Received} />,
  },
  {
    path: Routes.OMS.Returns.QC.path,
    element: <RouteWithSidebar component={QcCheck} />,
  },
  {
    path: Routes.OMS.Returns.RefundInitiated.path,
    element: <RouteWithSidebar component={RefundInitiated} />,
  },
  {
    path: Routes.OMS.Returns.Refunded.path,
    element: <RouteWithSidebar component={Refunded} />,
  },
  {
    path: Routes.OMS.Orders.PreBooked.path,
    element: <RouteWithSidebar component={PreBooked} />,
  },
  {
    path: Routes.OMS.Orders.PreRegistered.path,
    element: <RouteWithSidebar component={PreRegistered} />,
  },
  {
    path: Routes.OMS.Products.Released.path,
    element: <RouteWithSidebar component={ProductsRelease} />,
  },
  {
    path: Routes.SMS.Target.SchemeDetails.path,
    element: <RouteWithSidebar component={SchemeAnalysisComponent} />,
  },
  {
    path: Routes.SMS.Target.SMSUserList.path,
    element: <RouteWithSidebar component={SMSUserList} />,
  },
  {
    path: Routes.SMS.Target.SchemeInfo.path,
    element: <RouteWithSidebar component={ViewSchemeDetails} />,
  },
  {
    path: Routes.SecurityGuard.Add.path,
    element: <RouteWithSidebar component={AddSecurityGuardPage} />,
  },
  {
    path: Routes.SecurityGuard.EntryList.path,
    element: <RouteWithSidebar component={EntryList} />,
  },
  {
    path: Routes.OMS.Orders.QCFail.path,
    element: <RouteWithSidebar component={QCFailed} />,
  },
  {
    path: Routes.Admin.User.Role.path,
    element: <RouteWithSidebar component={Roles} />,
  },
  {
    path: Routes.OMS.Orders.Filters.path,
    element: <RouteWithSidebar component={FilteredOrders} />,
  },
  {
    path: Routes.OMS.Orders.allOrders.path,
    element: <RouteWithSidebar component={Allorders} />,
  },
  {
    path: Routes.OMS.Orders.onHold.path,
    element: <RouteWithSidebar component={OnHold} />,
  },
  {
    path: Routes.OMS.Orders.outForDelivery.path,
    element: <RouteWithSidebar component={OutForDelivery} />,
  },
  {
    path: Routes.OMS.DeliveryAgents.ManualAssign.path,
    element: <RouteWithSidebar component={ManualAssign} />,
  },
  {
    path: Routes.OMS.DeliveryAgents.GetHyperLocalOrders.path,
    element: <RouteWithSidebar component={getHyperLocalOrders} />,
  },
  {
    path: Routes.OMS.DeliveryAgents.ManageHyperLocal.path,
    element: <RouteWithSidebar component={ManageHyperLocalDelivery} />,
  },
  {
    path: Routes.OMS.Users.AddUser.path,
    element: <RouteWithSidebar component={AddUser} />,
  },
  {
    path: Routes.OMS.RegionOrders.Awaiting.path,
    element: <RouteWithSidebar component={AwaitingInvoiceRegion} />,
  },
  {
    path: Routes.OMS.Serviceability.Details.path,
    element: <RouteWithSidebar component={TestServiceability} />,
  },
  {
    path: Routes.OMS.Payment.Authorization.path,
    element: <RouteWithSidebar component={Authorization} />,
  },
  {
    path: Routes.Payments.Pay.path,
    element: <RouteWithSidebar component={PaymentChallans} />,
  },
  {
    path: Routes.Payments.Add.path,
    element: <RouteWithSidebar component={CreatePaymentVoucher} />,
  },
  {
    path: Routes.Payments.Sent.path,
    element: <RouteWithSidebar component={SentVouchers} />,
  },
  {
    path: Routes.Payments.Received.path,
    element: <RouteWithSidebar component={ReceivedVouchers} />,
  },
  {
    path: Routes.Payments.Print.path,
    element: <VoucherPrint />,
  },
  {
    path: Routes.OMS.CustomerPickup.Pending.path,
    element: <RouteWithSidebar component={Pending} />,
  },
  {
    path: Routes.OMS.CustomerPickup.Completed.path,
    element: <RouteWithSidebar component={Completed} />,
  },
  {
    path: Routes.OMS.Products.Sync.path,
    element: <RouteWithSidebar component={InventorySync} />,
  },
  {
    path: Routes.OMS.GST.Validation.path,
    element: <RouteWithSidebar component={validation} />,
  },
  {
    path: Routes.OMS.InterBranchTransfers.Create.path,
    element: <RouteWithSidebar component={CreateRequest} />,
  },
  {
    path: Routes.OMS.InterBranchTransfers.Received.path,
    element: <RouteWithSidebar component={IBTReceived} />,
  },
  {
    path: Routes.OMS.InterBranchTransfers.Sent.path,
    element: <RouteWithSidebar component={IBTSent} />,
  },
  {
    path: Routes.Payments.TripPayment.PerDeliveryRate.path,
    element: <RouteWithSidebar component={PerDeliveryRateEdit} />,
  },
  {
    path: Routes.Payments.TripPayment.Unpaid.path,
    element: <RouteWithSidebar component={UnpaidTripPayments} />,
  },
  {
    path: Routes.Payments.TripPayment.Paid.path,
    element: <RouteWithSidebar component={PaidTripPayments} />,
  },
  {
    path: Routes.OMS.DeliveryAgents.Analysis.path,
    element: <RouteWithSidebar component={BikerAnalysis} />,
  },
  {
    path: Routes.SMS.Target.RedesignAddScheme.path,
    element: <RouteWithSidebar component={AddSchemeRedesign} />,
  },
  {
    path: Routes.SMS.Target.RedesignDraftSchemes.path,
    element: <RouteWithSidebar component={RedesignDraftSchemes} />,
  },
  {
    path: Routes.SMS.Target.RedesignActiveSchemes.path,
    element: <RouteWithSidebar component={RedesignActiveSchemes} />,
  },
  {
    path: Routes.SMS.Target.RedesignClosedSchemes.path,
    element: <RouteWithSidebar component={RedesignClosedSchemes} />,
  },
  {
    path: Routes.SMS.Target.RedesignRejectedSchemes.path,
    element: <RouteWithSidebar component={RedesignRejectedSchemes} />,
  },
  {
    path: Routes.SMS.Target.EditScheme.path,
    element: <RouteWithSidebar component={EditScheme} />,
  },
  {
    path: Routes.SMS.Target.RedesignSchemeDetails.path,
    element: <RouteWithSidebar component={RedesignSchemeAnalysisComponent} />,
  },
  {
    path: Routes.SMS.Target.AddSchemeProduct.path,
    element: <RouteWithSidebar component={AddProductsinScheme} />,
  },
  {
    path: Routes.SMS.Target.SchemeDashboard.path,
    element: <RouteWithSidebar component={SchemeDashboard} />,
  },
  {
    path: Routes.OMS.Dashboard.Home.path,
    element: <RouteWithSidebar component={Analytics} />,
  },
  {
    path: Routes.OMS.Dashboard.Home.path,
    element: <RouteWithSidebar component={Analytics} />,
  },
  {
    path: Routes.OMS.Products.List.path,
    element: <RouteWithSidebar component={ProductsList} />,
  },
  {
    path : Routes.OMS.Orders.Delayed.path,
    element: <RouteWithSidebar component={DelayedOrdersAnalytics} />
  },
  {
    path: Routes.OMS.Products.Details.path,
    element: <RouteWithSidebar component={ProductDetails} />,
  },
  {
    path: Routes.OMS.EndlessAisle.Pending.path,
    element: <RouteWithSidebar component={EndlessPending} />,
  },
  {
    path: Routes.OMS.EndlessAisle.Processing.path,
    element: <RouteWithSidebar component={EndlessProcessing} />,
  },
  {
    path: Routes.OMS.Products.UpdateInventory.path,
    element: <RouteWithSidebar component={UpdateInventory} />,
  },
  {
    path : Routes.OMS.Orders.courierPending.path,
    element : <RouteWithSidebar component={CourierPickup} />
  },
  {
    path : Routes.OMS.Orders.hyperLocalPending.path,
    element : <RouteWithSidebar component={HyperLocalPickup} />
  },
  {
    path : Routes.OMS.Orders.customerPickup.path,
    element : <RouteWithSidebar component={CustomerPickup} />
  },
  {
    path: Routes.Reports.VehiclePayments.path,
    element: <RouteWithSidebar component={PaymentReports} />,
  },
]);

export default router;
