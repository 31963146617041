import { returnReadableDate } from '../datetime';
import { returnCustomerAddressString } from '../table';
const formatDecimal = (cell) => {
  if (typeof cell === 'number') {
    return cell.toFixed(3);
  }
  return cell;
};

export const individualSchemeTable = (type) => {
  if (type === 'Sell-in') {
    return [
      {
        dataField: 'Branch',
        sort: true,
        text: 'Branch',
      },
      {
        dataField: 'PurchaseDate',
        sort: true,
        text: 'Purchase Date',
        formatter: (cell, row) => {
          return returnReadableDate(cell);
        },
      },
      {
        dataField: 'Product',
        text: 'Product',
        formatter: (cell, row) => {
          return returnCustomerAddressString(cell, null, 200, 30, 'top');
        },
      },
      {
        dataField: 'PurchaseVNo',
        text: 'Voucher No.',
      },
      {
        dataField: 'TotalQuantity',
        text: 'Quantity',
      },
      {
        dataField: 'TotalAmount',
        text: 'Amount',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalSGST',
        text: 'SGST',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalCGST',
        text: 'CGST',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalIGST',
        text: 'IGST',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalGSTAmount',
        text: 'GST Amount',
        formatter: formatDecimal,
      },
    ];
  } else {
    return [
      {
        dataField: 'branch',
        sort: true,
        text: 'Branch',
      },
      {
        dataField: 'product',
        text: 'Product',
        formatter: (cell, row) => {
          return returnCustomerAddressString(cell, null, 200, 30, 'top');
        },
      },
      {
        dataField: 'serialNo',
        text: 'Serial No',
      },
      {
        dataField: 'invoiceNo',
        text: 'Invoice No',
      },
      {
        dataField: 'vanNo',
        text: 'VanNo',
      },
      {
        dataField: 'quantity',
        text: 'Quantity',
      },
      {
        dataField: 'amount',
        text: 'Amount',
      },
      {
        dataField: 'SGST',
        text: 'SGST',
      },
      {
        dataField: 'CGST',
        text: 'CGST',
      },
      {
        dataField: 'IGST',
        text: 'IGST',
      },
    ];
  }
};

export const sellOutTable = () => {
  return [
    {
      dataField: 'product',
      text: 'Product',
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell, null, 300, 30, 'top');
      },
    },
    {
      dataField: 'vanNo',
      text: 'VanNo',
    },
    {
      dataField: 'payout',
      text: 'Payout',
    },
    {
      dataField: 'valuationType',
      text: 'Valuation',
    },
    {
      dataField: 'currentQuantity',
      text: 'Current Quantity',
    },
    {
      dataField: 'currentValue',
      text: 'Current Value',
      formatter: formatDecimal,
    },
    {
      dataField: 'totalPositiveQuantity',
      text: 'Sold Quantity',
    },
    {
      dataField: 'totalNegativeQuantity',
      text: 'Return Quantity',
    },
    {
      dataField: 'cappingType',
      text: 'Capping Type',
    },
    {
      dataField: 'cappingQuantity',
      text: 'Target',
    },
  ];
};
