import {
  RESET_FILEDATA_IN_STORE,
  RESET_STORE,
  SET_FILEDATA_IN_STORE,
} from '../../actions/types';

const INITIAL_STATE = {};

const fileDatas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILEDATA_IN_STORE: {
      const { source, data } = action.data;
      return {
        ...state,
        [source]: data,
      };
    }
    case RESET_FILEDATA_IN_STORE: {
      return INITIAL_STATE;
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default fileDatas;
