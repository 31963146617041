import { Modal, Spinner } from '@themesberg/react-bootstrap';
import React from 'react';

const ProcessingModal = ({ showModal, closeModal, message }) => {
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
      backdrop="static"
      keyboard={false}
      aria-labelledby="processing-modal"
    >
      <Modal.Body className="text-center">
        <Spinner animation="border" role="status" className="mb-3">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <h5>{message || 'Processing...'}</h5>
      </Modal.Body>
    </Modal>
  );
};

export default ProcessingModal;
