export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export const convertToExcelFormat = (data, startDate, endDate) => {
  const { globalAverages, selectedStores } = data;
  if (!startDate && !endDate) {
    return;
  }
  // Convert global averages to rows
  const globalAveragesRows = {
    Assigned_Accepted: globalAverages?.Assigned_Accepted?.avg?.toFixed(2),
    Accepted_AwaitingInvoice:
      globalAverages?.Accepted_AwaitingInvoice?.avg?.toFixed(2),
    AwaitingInvoice_Invoiced:
      globalAverages?.AwaitingInvoice_Invoiced?.avg?.toFixed(2),
    Invoiced_ReadyForPickup:
      globalAverages?.Invoiced_ReadyForPickup?.avg?.toFixed(2),
    ReadyForPickup_PickedUp:
      globalAverages?.ReadyForPickup_PickedUp?.avg?.toFixed(2),
    PickedUp_OutForDelivery:
      globalAverages?.PickedUp_OutForDelivery?.avg?.toFixed(2) || 0,
    OutForDelivery_Delivered:
      globalAverages?.PickedUp_OutForDelivery?.avg?.toFixed(2) || 0,
    StoreCode: 'All_Stores_Average',
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };

  // Convert selected stores to rows
  const storeRows = Object.keys(selectedStores).map((storeKey) => {
    const store = selectedStores[storeKey];
    return {
      StoreCode: store?.storeCode,
      City: store?.city,
      Assigned_Accepted: store?.Assigned_Accepted?.avg?.toFixed(2),
      Accepted_AwaitingInvoice:
        store?.Accepted_AwaitingInvoice?.avg?.toFixed(2),
      AwaitingInvoice_Invoiced:
        store?.AwaitingInvoice_Invoiced?.avg?.toFixed(2),
      Invoiced_ReadyForPickup: store?.Invoiced_ReadyForPickup?.avg?.toFixed(2),
      ReadyForPickup_PickedUp: store?.ReadyForPickup_PickedUp?.avg?.toFixed(2),
      PickedUp_OutForDelivery: store?.PickedUp_OutForDelivery?.avg?.toFixed(2),
      OutForDelivery_Delivered:
        store?.OutForDelivery_Delivered?.avg?.toFixed(2),
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
  });
  return [...storeRows, globalAveragesRows];
};
