import React, { useEffect, useState } from 'react';
import { StyledSearchBar } from '../trips/styles';
import { Button, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import ExcelDownloadReport from '../../components/common/excel/download';
import {
  faArrowRotateLeft,
  faRefresh,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from './DatePicker';
import EditableDropDown from '../../components/common/EditableDropdown';
import { ErrorMessage } from '../scheme-management/Components/StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import { inputValidate, unpaidTripsColumns } from './utils';
import { updateSpinnerState } from '../../actions/spinner';
import { convertArrayToObj } from '../../utils/json';
import { updateToastInfo } from '../../actions/settings';
import { setVendorsListInStore } from '../../actions/vendors';
import { getVendorsListInRegion } from '../../parse-functions/vendors';
import { getTripsPaymentList } from '../../parse-functions/payments';
import TableOnly from '../scheme-management/Components/IndividualScheme.js/TableOnly';
import TripsPaymentModal from './TripsPaymentModal';
import { returnUserReadableDate } from '../../utils/datetime';

const UnpaidTripPayments = () => {
  const initialFormInput = {
    fromDate: '',
    toDate: '',
    vendorName: '',
    vehicleNumber: '',
    zone: '',
  };
  const configData = useSelector((state) => state.user.config || {});
  const [searchTrip, setSearchTrip] = useState('');
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [tripsData, setTripsData] = useState([]);
  const [formInput, setFormInput] = useState(initialFormInput);
  const [formErrors, setFormErrors] = useState(initialFormInput);
  const [vendorOptions, setVendorOptions] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [deliveryRate, setDeliveryRate] = useState(0);
  const vendorsList = useSelector((state) => state?.vendors?.vendorsList || {});
  const [zones, setZones] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filteredTrips = tripsData.filter((trip) =>
    trip.tripNumber.toLowerCase().includes(searchTrip.toLowerCase())
  );

  const handleFilterClicked = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const formData = { ...formInput };
      formData.fromDate = new Date(formData.fromDate);
      formData.fromDate.setHours(0, 0, 0, 0);

      formData.toDate = new Date(formData.toDate);
      formData.toDate.setHours(23, 59, 59, 999);
      const inputValid = inputValidate(formData, setFormErrors);
      if (!inputValid) {
        dispatch(updateSpinnerState(false));
        return;
      }
      const data = await getTripsPaymentList({
        filters: {
          ...formData,
          vendorId: vendorOptions[formData.vendorName],
        },
      });
      setTripsData(data.result);
      setDeliveryRate(data.deliveryRate);
      setSelectAll(false);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t(''),
          message: t('Failed to fetch data!'),
        })
      );
    }
  };

  const selectAllClicked = () => {
    if (!selectAll) {
      setTripsData((prev) =>
        prev.map((item) => ({
          ...item,
          isSelected: true,
        }))
      );
    } else {
      setTripsData((prev) =>
        prev.map((item) => ({
          ...item,
          isSelected: false,
        }))
      );
    }
    setSelectAll((prev) => !prev);
  };

  const onRowSelect = (selected, objectId) => {
    setTripsData((prev) =>
      prev.map((item) => {
        if (item.objectId === objectId) {
          return {
            ...item,
            isSelected: selected,
          };
        } else {
          return item;
        }
      })
    );
  };
  useEffect(() => {
    const fetchVendor = async () => {
      if (Object.keys(vendorsList).length > 0) {
        const vendorObj = {};
        Object.values(vendorsList).forEach(
          (item) => (vendorObj[item['vendorName']] = item['objectId'])
        );
        setVendorOptions(vendorObj);
        return;
      }
      dispatch(updateSpinnerState(true));
      try {
        const filters = {
          status: 'active',
          getAllVendors: true,
        };
        let vendors = await getVendorsListInRegion(filters);
        dispatch(
          setVendorsListInStore(
            convertArrayToObj(JSON.parse(JSON.stringify(vendors)), 'objectId')
          )
        );
        vendors = JSON.parse(JSON.stringify(vendors));
        const vendorObj = vendors?.reduce((acc, row) => {
          acc[row['vendorName']] = row['objectId'];
          return acc;
        }, {});
        setVendorOptions(vendorObj);
        dispatch(updateSpinnerState(false));
      } catch (e) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Error'),
            message: t(e.message),
          })
        );
      }
    };
    setZones([
      { label: 'All', value: 'All' },
      ...(arrayToDropdownOptions(Object.keys(configData.zones || {})) || []),
    ]);
    fetchVendor();
  }, []);
  return (
    <div className="p-3">
      <div>
        <h4>Unpaid Trips Payment Overview</h4>
      </div>
      <div className="d-flex mb-3 justify-content-end align-items-center">
        <div className="btn-toolbar mb-2 mb-md-0 gap-2">
          <Button
            variant={showFilterOptions ? 'primary' : 'white'}
            onClick={() => {
              setShowFilterOptions((prev) => !prev);
            }}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
            }}
            size="sm"
          >
            <FontAwesomeIcon icon={faSliders} />
            &nbsp;&nbsp;{t('Filter')}
          </Button>
          <Button
            variant="white"
            onClick={() => {
              handleFilterClicked();
            }}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
            }}
            size="sm"
          >
            <FontAwesomeIcon icon={faRefresh} />
            &nbsp;&nbsp;{t('Refresh')}
          </Button>

          <ExcelDownloadReport
            style={{ width: '150px', height: '38px', borderRadius: 0 }}
            size="sm"
            data={
              tripsData.map((item) => {
                return {
                  tripNumber: item.tripNumber,
                  vehicleNumber: item.driverInfo?.vehicleNumber,
                  vendorName: item.driverInfo?.vendorName,
                  paymentMode: item.driverInfo?.paymentMode,
                  vehicleType: item.driverInfo?.vehicleType,
                  deliveryQuantity: item.deliveryRateInfo?.deliveryQuantity,
                  defectivePicked: item.deliveryRateInfo?.defectivePicked,
                  package: item.deliveryRateInfo?.package,
                  totalAmount: item.totalAmount,
                  tripDate: returnUserReadableDate(item.dispatchedAt),
                };
              }) || []
            }
            fileName={`${
              formInput.vendorName || formInput.vehicleNumber
            }_UnpaidTripsData.csv`}
          />
        </div>
      </div>
      {showFilterOptions && (
        <>
          <div
            className="table-settings mt-4 mb-4 p-3"
            style={{
              background: 'white',
              transition: 'max-height 0.7s ease-in-out',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '20px',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <Form.Group>
                <Form.Label>From Date</Form.Label>
                <DatePicker
                  value={formInput['fromDate']}
                  setValue={(date) =>
                    setFormInput((prev) => ({
                      ...prev,
                      fromDate: date,
                    }))
                  }
                  isInvalid={formErrors['fromDate']}
                  errorMessage={t(formErrors['fromDate'])}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  value={formInput['toDate']}
                  setValue={(date) =>
                    setFormInput((prev) => ({
                      ...prev,
                      toDate: date,
                    }))
                  }
                  isInvalid={formErrors['toDate']}
                  errorMessage={t(formErrors['toDate'])}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Vendor Name</Form.Label>
                <EditableDropDown
                  id={'vendorName'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      vendorName: e.value,
                    }))
                  }
                  backgroundColor="#eaedf2"
                  isInvalid={formErrors['vendorName']}
                  value={formInput['vendorName']}
                  options={arrayToDropdownOptions(Object.keys(vendorOptions))}
                  placeholder={'Select Vendor Name'}
                />
                <ErrorMessage>{t(formErrors['vendorName'])}</ErrorMessage>
              </Form.Group>
              <Form.Group>
                <Form.Label>Vehicle Number</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                    backgroundColor: '#eaedf2',
                  }}
                  type="text"
                  value={formInput['vehicleNumber']}
                  isInvalid={formErrors['vehicleNumber']}
                  onChange={(e) => {
                    e.persist();
                    setFormInput((prev) => ({
                      ...prev,
                      vehicleNumber: e.target.value,
                    }));
                  }}
                  placeholder="Enter the Vehicle No."
                />
                <ErrorMessage>{t(formErrors['vehicleNumber'])}</ErrorMessage>
              </Form.Group>
              <Form.Group>
                <Form.Label>Zone</Form.Label>
                <EditableDropDown
                  id={'zone'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      zone: e.value,
                    }))
                  }
                  backgroundColor="#eaedf2"
                  isInvalid={formErrors['zone']}
                  value={formInput['zone']}
                  options={zones}
                  placeholder={'Select Zone'}
                />
                <ErrorMessage>{t(formErrors['zone'])}</ErrorMessage>
              </Form.Group>
            </div>
            <div
              style={{
                gridColumn: 'span 3', // Span across all columns
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <Button
                variant="white"
                onClick={() => setFormInput(initialFormInput)}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  background: '#EAEDF2',
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateLeft} />
                &nbsp;&nbsp;{t('Reset')}
              </Button>
              <Button
                variant="white"
                onClick={handleFilterClicked}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  border: '1px solid #262B40',
                }}
              >
                <FontAwesomeIcon icon={faSliders} />
                &nbsp;&nbsp;{t('Apply Filter')}
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="d-flex justify-content-between align-items-center p-2 mt-3 mb-2">
        <div
          className="d-flex align-items-center p-2"
          style={{
            width: '125px',
            height: '38px',
            borderRadius: 0,
            backgroundColor: 'white',
          }}
        >
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Select All"
              checked={selectAll}
              onChange={selectAllClicked}
              disabled={tripsData.length === 0}
            />
          </Form.Group>
        </div>
        <div className="d-flex gap-3">
          <StyledSearchBar
            //   size="xs"
            width={'360.89px'}
            height={'38px'}
            type={'text'}
            value={searchTrip}
            placeholder={'Search Trip No.'}
            onChange={(e) => setSearchTrip(e.target.value)}
          />
          <Button
            variant="white"
            onClick={() => {
              setShowPaymentModal(true);
            }}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
            }}
            size="sm"
          >
            {t('Mark as Paid')}
          </Button>
        </div>
      </div>
      <TableOnly
        data={filteredTrips}
        columns={unpaidTripsColumns({ onRowSelect })}
      />
      {showPaymentModal && (
        <TripsPaymentModal
          showModal={showPaymentModal}
          closeModal={() => setShowPaymentModal(false)}
          tripsData={tripsData.filter((item) => item.isSelected)}
          deliveryRate={deliveryRate}
        />
      )}
    </div>
  );
};

export default UnpaidTripPayments;
