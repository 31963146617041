import React, { useEffect, useState } from 'react';
import { productListColumns } from '../products/utils';
import NewTable from '../../../components/common/NewTable';
import { Badge, Button } from '@themesberg/react-bootstrap';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { useTranslation } from 'react-i18next';
// import { Icon, InlineIcon } from '@iconify/react/dist/iconify.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faSliders } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import {
  endlessAisleProcessingColumns,
  endlessOptions,
  endlessStatus,
} from './utils';
import DataModal from './components/DataModal';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterOrders from '../orders/FIlterOrders';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import { useFetchStores } from '../custom-hooks/useFetchStores';
import { orderStatus } from '../../../constants/orderStatus';

const EndlessProcessing = () => {
  const { t } = useTranslation();
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const type = 'Processing';
  const [ordersData, setOrdersData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showDataModal, setShowDataModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const [storeDropdownAdded, setStoreDropdownAdded] = useState(false);
  const [queryObject, setQueryObject] = useState({});
  const [queryParam, setQueryParam] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?isEndlessAisle=true&isProgress=true`,
        {
          ...queryParam,
          storeCode: isAdmin ? queryParam?.storeCode || '' : storeName,
          zone: isAdmin
            ? Array.isArray(queryParam?.zone)
              ? queryParam?.zone?.join(',')
              : queryParam?.zone || ''
            : zone,
          status: type,
        }
      );
      setOrdersData(res);
      setFilterData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  const onActionClick = async (option, objectId) => {
    setSelectedOrderId(objectId);
    switch (option) {
      case endlessOptions.brandInvoiceReceived:
        setModalTitle(endlessOptions.brandInvoiceReceived);
        setConfirmationModalProps([
          {
            title: 'Invoice Number',
            type: 'text',
            value: '',
          },
          {
            title: 'Invoice Date',
            type: 'date',
            value: '',
          },
          {
            title: 'Vendor/Supplier Name',
            type: 'text',
            value: '',
          },
          {
            title: 'Total Invoice Amount',
            type: 'number',
            value: '',
          },
        ]);
        setShowDataModal(true);
        break;
      case endlessOptions.customerInvoiceRaised:
        setModalTitle(endlessOptions.customerInvoiceRaised);
        setConfirmationModalProps([
          {
            title: 'Invoice Number',
            type: 'text',
            value: '',
          },
          {
            title: 'Invoice Date',
            type: 'date',
            value: '',
          },
          {
            title: 'Total Invoice Amount',
            type: 'number',
            value: '',
          },
        ]);
        setShowDataModal(true);
        break;
      case endlessOptions.assignStore:
        setModalTitle(endlessOptions.assignStore);
        setShowDataModal(true);
        break;
      case endlessOptions.deliveryDetails:
        setModalTitle(endlessOptions.deliveryDetails);
        setConfirmationModalProps([
          {
            title: 'AWB No',
            type: 'text',
            value: '',
          },
          {
            title: 'Delivery By',
            type: 'text',
            value: '',
          },
          {
            title: 'Delivery Date',
            type: 'date',
            value: '',
          },
          {
            title: 'Delivery Address',
            type: 'textArea',
            value: '',
          },
        ]);
        setShowDataModal(true);
        break;
      case orderStatus.cancelled:
        setModalTitle(orderStatus.cancelled);
        setConfirmationModalProps([
          {
            type: 'text',
            title: 'Remarks',
            value: '',
          },
        ]);
        setShowDataModal(true);
        break;
      case endlessOptions.delivered:
        setModalTitle(endlessOptions.delivered);
        setShowDataModal(true);
        break;
      default:
        navigate(`/oms/orders/${objectId}`);
        break;
    }
  };

  const handleUpdate = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(apiCallConsts.PUT_METHOD, 'internal/order/update', data);
      messageDisplayer('success', '', 'order updated successfully!');
      await fetchOrders();
      setSelectedOrderId('');
      setShowDataModal(false);
      setConfirmationModalProps([]);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', '', error.message);
    }
  };

  const handleModalSubmit = () => {
    if (
      confirmationModalProps.some(
        (each) => each.type !== 'switch' && !each.value
      )
    ) {
      messageDisplayer(
        'danger',
        '',
        'Please fill all details before submitting'
      );
      return;
    }
    const data = {
      id: [selectedOrderId],
      //   status: modalTitle,
    };
    switch (modalTitle) {
      case endlessOptions.brandInvoiceReceived:
        data.brandInvoiceNo = confirmationModalProps[0].value;
        data.brandInvoiceDate = confirmationModalProps[1].value;
        data.brandSupplierName = confirmationModalProps[2].value;
        data.brandTotalAmount = confirmationModalProps[3].value;
        data.status = endlessStatus.brandInvoiceReceived;
        break;
      case endlessOptions.customerInvoiceRaised:
        data.customerInvoiceNo = confirmationModalProps[0].value;
        data.customerInvoiceDate = confirmationModalProps[1].value;
        data.customerTotalAmount = confirmationModalProps[2].value;
        data.status = endlessStatus.customerInvoiceRaised;
        break;
      case endlessOptions.assignStore:
        data.status = orderStatus.accepted;
        data.storeCode = confirmationModalProps[0]?.value;
        break;
      case endlessOptions.deliveryDetails:
        data.awbNo = confirmationModalProps[0].value;
        data.deliveryBy = confirmationModalProps[1].value;
        data.deliveryDate = confirmationModalProps[2].value;
        data.deliveryAddress = confirmationModalProps[3].value;
        data.status = orderStatus.pickedUp;
        break;
      case endlessOptions.delivered:
        data.status = orderStatus.delivered;
        break;
      case orderStatus.cancelled:
        data.remarks = confirmationModalProps[0].value;
        data.status = orderStatus.cancelled;
        break;
    }
    handleUpdate(data);
  };

  useEffect(() => {
    const shouldAddDropdown = modalTitle === endlessOptions.assignStore;
    if (shouldAddDropdown && !storeDropdownAdded) {
      setConfirmationModalProps([
        {
          type: 'dropdown',
          title: 'Store',
          options: returnWarehouseDropdownOptions(storeList, false),
          value: [],
        },
      ]);
      setStoreDropdownAdded(true);
    }
  }, [modalTitle, storeDropdownAdded]);

  useFetchStores();

  useEffect(() => {
    const newQueryObject = {};
    queryParams.forEach((value, key) => {
      queryObject[key] = value;
    });

    if (Object.keys(newQueryObject).length > 0) {
      setQueryObject(newQueryObject);
    }
  }, [location.search]);
  useEffect(() => {
    if (Object.keys(queryObject).length > 0) {
      console.log(queryObject, 'this is to type state');
      setQueryParam({ ...queryObject, status: type });
    }
  }, [queryObject, isAdmin]);

  // Fetch orders when queryParam changes
  useEffect(() => {
    const toSearch = Object.values(queryParam).filter(
      (each) => each && each != type
    );
    if (
      !isAdmin ||
      (toSearch?.length > 0 &&
        queryParam &&
        Object.keys(queryParam || {}).length > 0)
    ) {
      fetchOrders();
    } else {
      setOrdersData([]);
    }
  }, [queryParam]);

  // useEffect(() => {
  //   fetchOrders();
  // }, []);

  return (
    <>
      {/* <fieldset disabled={true}> */}
      <div className="fw-medium fs-4 my-2">{t('Endless Aisle List')}</div>
      <div className="d-flex mb-2 justify-content-end gap-3">
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <FontAwesomeIcon icon={faSliders} />
          &nbsp;&nbsp;{t('Filter')}
        </Button>
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={fetchOrders}
        >
          <FontAwesomeIcon icon={faRotateRight} />
          {/* <InlineIcon
            icon={'lucide:rotate-cw'}
            width="1.25rem"
            height="1.25rem"
          /> */}
          &nbsp;&nbsp; {t('Refresh')}
        </Button>
        <ExcelDownloadReport
          data={ordersData || []}
          fileName="Endless Aisle orders"
        />
      </div>
      {openFilter && (
        <FilterOrders
          data={filterData}
          setData={setOrdersData}
          openFilter={openFilter}
          pageType={`${type}orders`}
          setQueryParam={setQueryParam}
        />
      )}
      <NewTable
        id={'endlessProcessing'}
        data={ordersData || []}
        columns={endlessAisleProcessingColumns(
          onActionClick,
          type,
          roles.includes('admin') || roles.includes('superAdmin')
        )}
      />
      {/* {showDataModal && ( */}
      <DataModal
        showModal={showDataModal}
        closeModal={() => setShowDataModal(false)}
        modalTitle={modalTitle}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
        handleSubmit={handleModalSubmit}
      />
      {/* )} */}
      {/* </fieldset> */}
    </>
  );
};

export default EndlessProcessing;
