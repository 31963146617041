import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from '@themesberg/react-bootstrap';

const DynamicMultiInputModal = ({
  show,
  handleClose,
  onSuccess,
  onCancel,
  inputFields = [],
  setConfirmationModalProps,
}) => {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  // Reset input fields and errors
  const resetFields = () => {
    setInputs({});
    setErrors({});
  };

  const handleInputChange = (key, value) => {
    // Update input state
    setInputs((prev) => ({ ...prev, [key]: value }));

    // Dynamically update `setConfirmationModalProps` at index 0
    setConfirmationModalProps((prev) => {
      const updatedProps = [...prev];
      if (!updatedProps[0]) updatedProps[0] = {}; // Ensure index 0 exists
      updatedProps[0] = { ...updatedProps[0], [key]: value }; // Merge new values
      return updatedProps;
    });
  };

  const handleSaveAndProceed = async () => {
    let isValid = true;
    const newErrors = {};

    // Validate all fields

    if (isValid) {
      onSuccess(inputs); // Pass all inputs back to parent
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        resetFields();
        handleClose();
      }}
      centered
      style={{
        textAlign: 'center',
      }}
    >
      <Modal.Body
        style={{
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h5
          style={{
            fontWeight: '600',
            fontSize: '22px',
            color: '#333',
            marginBottom: '10px',
          }}
        >
          Enter Required Details
        </h5>
        <p
          style={{
            fontSize: '14px',
            color: '#555',
            marginBottom: '20px',
          }}
        >
          Please enter the valid details to proceed with the order.
        </p>

        {/* Dynamic Input Fields */}
        {inputFields.map(({ key, placeholder, validationLength }, index) => (
          <Form.Group
            key={key}
            style={{
              marginBottom: '20px',
              width: '100%',
              maxWidth: '400px',
            }}
          >
            <Form.Control
              type="text"
              placeholder={placeholder}
              value={inputs[key] || ''}
              onChange={(e) => handleInputChange(key, e.target.value, index)}
              style={{
                fontSize: '14px',
                padding: '10px',
                borderColor: errors[key] ? 'red' : '#ccc',
                borderRadius: '4px',
              }}
            />
            {errors[key] && (
              <small
                style={{
                  color: 'red',
                  display: 'block',
                  textAlign: 'left',
                  marginTop: '5px',
                }}
              >
                {errors[key]}
              </small>
            )}
          </Form.Group>
        ))}

        {/* Buttons */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '30px',
            width: '80%',
            maxWidth: '400px',
          }}
        >
          <Button
            variant="light"
            className="text-gray"
            style={{
              padding: '10px 0',
              width: '30%',
              fontSize: '14px',
              fontWeight: '500',
              backgroundColor: '#F0F0F0',
              borderRadius: '4px',
            }}
            onClick={() => {
              resetFields();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            style={{
              padding: '10px 0',
              width: '30%',
              fontSize: '14px',
              fontWeight: '500',
              color: '#fff',
              backgroundColor: '#444444',
              borderRadius: '4px',
            }}
            onClick={handleSaveAndProceed}
          >
            Save and Proceed
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DynamicMultiInputModal;
