import React from 'react';

const RenderObject = ({ obj, parentKey = '' }) => {
  const convertCamelCaseToNormal = (text) => {
    let finalStr = '';
    for (let i = 0; i < text.length; i++) {
      const letter = text[i];
      if (i === 0) {
        finalStr += letter.toUpperCase();
      } else if (letter === letter.toUpperCase() && letter !== ' ') {
        finalStr += ' ' + letter.toLowerCase();
      } else {
        finalStr += letter;
      }
    }
    return finalStr;
  };

  return (
    <div>
      {Object.keys(obj).map((key) => {
        const value = obj[key];

        const displayKey = key;

        if (!value && value !== 0) {
          return null;
        }

        return typeof value === 'object' && value !== null ? (
          <RenderObject key={displayKey} obj={value} parentKey={displayKey} />
        ) : (
          <div
            key={displayKey}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '1rem',
            }}
          >
            <div className="fw-medium w-50">
              {convertCamelCaseToNormal(displayKey)}:
            </div>
            <div
              style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                maxWidth: '50%',
              }}
            >
              {String(value)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RenderObject;
