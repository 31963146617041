import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumbTitle from '../../scheme-management/Components/common/BreadCrumbTitle';
import { Routes } from '../../../router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Form } from '@themesberg/react-bootstrap';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { useTranslation } from 'react-i18next';
import { useFetchStores } from '../custom-hooks/useFetchStores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import NewTable from '../../../components/common/NewTable';
import {
  citiesToRemove,
  summaryModalColumns,
  updateInventoryColumns,
} from './utils';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateToastInfo } from '../../../actions/settings';
import SummaryModal from './components/SummaryModal';
import { generateRandomId } from '../../../utils/string';
import { resetFileDataInStore } from '../../../actions/oms/fileData';

const UpdateInventory = () => {
  const { t } = useTranslation();
  const product = useSelector((state) => state?.allProducts?.product || {});
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const fileDatas = useSelector((state) => state?.fileDatas || {});
  const source = Object.keys(fileDatas)[0] || '';
  const fileData = fileDatas[source] || [];
  const [selectedCities, setSelectedCities] = useState([]);
  const [copySafety, setCopySafety] = useState(false);
  const [copyInventory, setCopyInventory] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  // const [isFileData, setIsFileData] = useState(false);
  const [data, setData] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const citiesAndStoreMap =
    useMemo(
      () =>
        storeList
          ?.filter((store) => !citiesToRemove.has(store.city))
          ?.reduce((accu, warehouse) => {
            if (warehouse?.city) {
              if (!accu[warehouse?.city]) {
                accu[warehouse?.city] = new Set();
              }
              accu[warehouse?.city].add(warehouse?.shortCode);
            }
            return accu;
          }, {}),
      [storeList]
    ) || {};

  useFetchStores();

  const handleChange = (options) => {
    const selectedOptions = options.find((option) => option.value === 'ALL')
      ? Object?.keys(citiesAndStoreMap)
      : options?.map((option) => option.value);
    setSelectedCities(selectedOptions);
    if (options.length === 0) setCopyData([]);
  };

  const handleFetch = async () => {
    if (!Object.keys(product).length > 0) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: 'Product is not found',
        })
      );
      return;
    }
    try {
      const data = {
        vanNo: product?.vanNo || '',
        citiesArray: selectedCities.join(','),
      };
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.INVENTORY_URL,
        data
      );
      const { city } = response;
      let d = [];
      const lastCopyData =
        copyData?.length > 0 ? copyData[copyData?.length - 1] : {};
      selectedCities.forEach((item) => {
        const {
          available = '',
          data = [],
          safetyStock = '',
          totalStock = '',
        } = city[item] || {};
        const previousItem = copyData.find((e) => e.cityName === item);
        const updatedAvailable = copyInventory
          ? previousItem
            ? previousItem.Uavailable || ''
            : lastCopyData.Uavailable || ''
          : '';
        const updatedSafetyStock = copySafety
          ? previousItem
            ? previousItem.UsafetyStock || ''
            : lastCopyData.UsafetyStock || ''
          : '';
        if (data.length > 0) {
          d.push({
            objectId: data[0]?.objectId,
            cityName: item,
            vanNo: product?.vanNo || '',
            available: totalStock || '',
            deliveryNote: previousItem
              ? previousItem?.deliveryNote
              : data[0]?.deliveryNote || '',
            edd: previousItem ? previousItem?.edd : data[0]?.edd || '',
            safetyStock: safetyStock,
            storeCode: previousItem
              ? previousItem?.storeCode
              : data[0]?.storeCode,
            Uavailable: updatedAvailable,
            UsafetyStock: updatedSafetyStock,
          });
        }
      });
      const filteredData = copyData.filter((item) =>
        selectedCities.includes(item.cityName)
      );
      const existingDataMap = filteredData.reduce((acc, item) => {
        acc[item.cityName] = item;
        return acc;
      }, {});
      const newData = d.map((e) => {
        if (existingDataMap[e.cityName]) {
          return {
            ...e,
            ...existingDataMap[e.cityName],
          };
        } else {
          return e;
        }
      });
      setData(newData);
      setCopyData(newData);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const updateAddInventory = async () => {
    try {
      const apiData = {
        inventories: data?.map((each) => ({
          ean: String(each?.vanNo),
          fcId: each?.storeCode || '',
          quantity: Number(each?.Uavailable || ''),
          safetyStock: Number(each.UsafetyStock || ''),
          movementType: 'RTW',
          transactionType: 'Overwrite',
          deliveryNote: each?.deliveryNote || '',
          edd: Number(each?.edd || ''),
        })),
        isInternal: true,
      };
      if (product?.isManualInventory || source === 'manualInventory') {
        apiData.inventories = apiData.inventories.map((each) => ({
          ...each,
          isManual: true,
        }));
      }
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.POST_METHOD,
        'inventory/update',
        apiData
      );
      setSummaryModal(false);
      setData([]);
      setCopyData([]);
      setSelectedCities([]);
      setValues({});
      setCopyInventory(false);
      setCopySafety(false);
      console.log(response);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleUpdateSubmit = async () => {
    await updateAddInventory();
  };

  const handleSaveClick = () => {
    // setData((prev) =>
    //   prev.map((each) => ({ ...each, ...values[each?.objectId] }))
    // );
    setSummaryModal(true);
  };

  const getCityNameByShortCode = (shortCode) => {
    return (
      storeList?.find((store) => store.shortCode === shortCode)?.city || ''
    );
  };

  const uploadedData = (fileData = []) => {
    const data = fileData.map((row) => ({
      objectId: generateRandomId(10),
      vanNo: row['SKU'],
      cityName: getCityNameByShortCode(row['Store Code']),
      deliveryNote: row['Delivery Message'] || '',
      edd: row['Delivery Days'] || '',
      available: 0,
      safetyStock: 0,
      storeCode: row['Store Code'] || '',
      Uavailable: row['Store Wise Qty'],
      UsafetyStock: row['Store Wise Safety Stock'],
    }));

    if (selectedCities) {
      setSelectedCities([]);
    }
    setData(data);
  };

  const handleTableChange = (e, key, objectId) => {
    let value;
    if (key === 'storeCode') {
      value = e.value;
    } else {
      value = e.target.value;
    }
    if (key === 'deliveryNote' && value.length > 40) {
      setErrors((prev) => ({
        ...prev,
        [`${key}-${objectId}`]:
          'Delivery Note should not exceed 40 characters.',
      }));
      // return;
    } else {
      setErrors((prev) => ({ ...prev, [`${key}-${objectId}`]: '' }));
    }
    setData((prevData) =>
      prevData.map((each) =>
        each.objectId === objectId ? { ...each, [key]: value } : each
      )
    );
    setCopyData((prevData) =>
      prevData.map((each) =>
        each.objectId === objectId ? { ...each, [key]: value } : each
      )
    );
    if (key !== 'storeCode') {
      setTimeout(() => {
        document.getElementById(`${key}-${objectId}`).focus();
      }, 0);
    }
  };

  useEffect(() => {
    if (storeList?.length > 0) {
      uploadedData(fileData);
    }
  }, [fileDatas, storeList]);

  useEffect(() => {
    //clearing filedata from store
    return () => {
      if (Object.keys(fileDatas).length > 0) {
        dispatch(resetFileDataInStore());
      }
    };
  }, [fileDatas, dispatch]);

  return (
    <>
      <BreadCrumbTitle
        breadcrumbs={[
          { title: 'Products List', link: Routes.OMS.Products.List.path },
          { title: 'Update Inventory' },
        ]}
      />
      {fileData?.length === 0 && (
        <>
          <div className="d-flex justify-content-between">
            <div className="fw-medium mt-4" style={{ fontSize: '1.25rem' }}>
              {product?.productTitle || ''}
            </div>
          </div>

          <div
            className="d-flex justify-content-start align-items-center bg-white my-3 p-3"
            style={{ height: '9rem' }}
          >
            <Form.Group className="mb-2">
              <div className="text-start fs-5">{t(`Select City`)}</div>
              <div className="d-flex gap-4">
                <span style={{ minWidth: '20rem' }}>
                  <EditableDropDown
                    isMultiSelect={true}
                    onChange={handleChange}
                    value={selectedCities}
                    options={arrayToDropdownOptions([
                      'ALL',
                      ...Object?.keys(citiesAndStoreMap || {}),
                    ])}
                    placeholder="Select City (or All Cities)"
                    optionColor="white"
                    backgroundColor="rgba(240, 240, 240, 1)"
                  />
                </span>
                <Button
                  variant="primary"
                  className="rounded-0"
                  style={{ height: '2.5rem', textAlign: 'center' }}
                  disabled={selectedCities.length === 0}
                  onClick={handleFetch}
                >
                  <FontAwesomeIcon icon={faSync} /> &nbsp;&nbsp;{' '}
                  {t('FETCH DATA')}
                </Button>
              </div>
            </Form.Group>
          </div>
          <div className="d-flex justify-content-end align-items-center gap-3 bg-white my-2">
            <div className="d-flex align-items-center gap-3 p-2">
              <Form.Label className="mb-0 fs-6">
                Copy Inventory Value
              </Form.Label>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id={'copyInventoryValue'}
                  checked={copyInventory}
                  onChange={(e) => setCopyInventory(e.target.checked)}
                  className="custom-checkbox"
                />
              </Form.Group>
            </div>
            <div className="d-flex align-items-center gap-3 p-2">
              <Form.Label className="mb-0 fs-6">
                Copy Safety Stock Value
              </Form.Label>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id={'copySafetyValue'}
                  checked={copySafety}
                  onChange={(e) => setCopySafety(e.target.checked)}
                  className="custom-checkbox"
                />
              </Form.Group>
            </div>
          </div>
        </>
      )}
      <div className="mt-2">
        <NewTable
          id="updateInventory"
          data={data || []}
          columns={updateInventoryColumns(
            handleTableChange,
            citiesAndStoreMap,
            errors
          )}
        />
      </div>
      {data.length > 0 && (
        <div className="mt-5 d-flex justify-content-end">
          <ButtonGroup className="d-flex gap-4">
            <Button
              variant="white"
              className="rounded-0 p-2"
              style={{ width: '11rem' }}
              //   className="text-gray ms-auto"
              onClick={() => {
                setSelectedCities([]);
                setData([]);
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              className="rounded-0 p-2"
              variant="primary"
              style={{ width: '11rem' }}
              onClick={handleSaveClick}
            >
              {t('Save')}
            </Button>
          </ButtonGroup>
        </div>
      )}

      {summaryModal && (
        <SummaryModal
          showModal={summaryModal}
          closeModal={() => setSummaryModal(false)}
          modalTitile={'Inventory Update Summary'}
          data={data || []}
          columns={summaryModalColumns()}
          handleSubmit={handleUpdateSubmit}
          summaryTitle={!product?.isEndlessAisle && 'Note'}
          summaryMessage={
            !product?.isEndlessAisle &&
            'This is not a Endless Aisle product, updating inventory for this will result in, serviceability will use the updated inventory from now.'
          }
        />
      )}
    </>
  );
};

export default UpdateInventory;
