import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InventoryPages from '../inventory/components/InventoryPages';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { updateToastInfo } from '../../../actions/settings';
import { useDispatch } from 'react-redux';
import { productReleaseColumns } from './utils';
import { getProductDataByVANNo } from '../../../parse-functions/products';
import ProductReleaseModal from './components/ProductReleaseModal';

const ProductsRelease = () => {
  const { t } = useTranslation();
  const breadCrumbItems = arrayToBreadCrumbs([[t('products release')]]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    vanNo: '',
  });
  const [selectedProducts, setselectedProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ModalProps, setModalProps] = useState([]);
  const searchInputs = [
    {
      placeholder: 'Enter Van No',
      onchange: (event) => updateInputs('vanNo', event?.target?.value),
    },
  ];

  const updateInputs = (fieldName, value) => {
    setInputs((prev) => {
      return {
        ...prev,
        [fieldName]: value,
      };
    });
  };

  const fetchOrders = async () => {
    if (!inputs.vanNo || inputs.vanNo.length !== 6) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Please enter vanno'),
          message: t('vanno should not be empty and equal to six digits'),
        })
      );
      return;
    }
    try {
      dispatch(updateSpinnerState(true));
      const { product } = await getProductDataByVANNo(inputs);
      setData([product]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Products fetched successfully'),
          message: t('Products fetched successfully'),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch Orders'),
          message: t(error.message),
        })
      );
    }
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setselectedProducts((prev) => {
        const selectedItem = data.find((item) => item.objectId === objectId);
        return [...prev, selectedItem];
      });
    } else {
      setselectedProducts((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleItemBulkUpdate = (actionId) => {
    if (selectedProducts.length > 0) {
      switch (actionId) {
        case 'edit': {
          setModalProps([
            {
              type: 'date',
              title: 'Start Date',
              value: '',
            },
            {
              type: 'number',
              title: 'PreBookLimit',
              value: '',
            },
          ]);
          setShowModal(true);
          break;
        }
        default: {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('Select an Action'),
              message: t('Please select an action from the dropdown'),
            })
          );
          break;
        }
      }
    }
  };
  const handleModalOnConfirmClick = async () => {
    const data = {
      ids: selectedProducts.map((product) => product.objectId),
      startDate: ModalProps[0].value,
      preBookLimit: ModalProps[1].value,
    };

    try {
      dispatch(updateSpinnerState(true));
      await apiCall('put', `/internal/inventory/setDate`, data);
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(``),
          message: t('Update Successful!'),
        })
      );
      setShowModal(false);
      setselectedProducts([]);
      setModalProps([]);
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Cannot be updated!'),
          message: t(error.message),
        })
      );
    }
  };

  return (
    <>
      <InventoryPages
        id={'productsRelease'}
        title={t('Products Release')}
        subTitle={t('This is the List of Products to Release')}
        data={data}
        storesListTableColumns={productReleaseColumns(onRowSelect)}
        breadCrumbItems={breadCrumbItems}
        searchInputs={searchInputs}
        handleFetch={fetchOrders}
        bulkActionOptions={[{ label: 'Edit', value: 'edit' }]}
        enableBulkActions={selectedProducts.length > 0}
        hideBulkActions={false}
        handleItemBulkUpdate={handleItemBulkUpdate}
      />
      <ProductReleaseModal
        showModal={showModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowModal(false);
          setModalProps([]);
        }}
        modalBody={t(`please Enter date and limit for releasing products`)}
        modalTitle={t('Products Released')}
        ModalProps={ModalProps}
        setModalProps={setModalProps}
      />
    </>
  );
};

export default ProductsRelease;
