import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  Spinner,
} from '@themesberg/react-bootstrap';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleRight,
  faArrowRight,
  faCheckCircle,
  faCircleArrowRight,
  faEdit,
  faRotateRight,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNumberInStore } from '../../../actions/tablePage';
import AsyncDropdown from '../../../components/AsyncDropdown';
import {
  setProductListDropdownInStore,
  setProductsMapInStore,
} from '../../../actions/oms/allProducts';
import { t } from 'i18next';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { debounce } from '../../../utils/debounce';
import AsyncSelect from 'react-select/async';
import ProductsList from '../products/ProductsList';
import { useFetchOMSProducts } from '../custom-hooks/useFetchOMSProducts';
const ManageServiceability = () => {
  const [pinCode, setPinCode] = useState('');
  const [vanNo, setVanNo] = useState([]);
  const [selectedVanNos, setSelectedVanNos] = useState([]);
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);
  const [successIcon, setSuccessIcon] = useState(false);
  const [isProcessing, setInProcess] = useState(false);
  const productList = useSelector(
    (state) => state?.allProducts?.productsListDropdown || {}
  );
  const productsMap = useSelector(
    (state) => state?.allProducts?.productsMap || {}
  );

  useFetchOMSProducts();

  const vanNoOptions = useMemo(
    () => arrayToDropdownOptions(Object.keys(productsMap)),
    [productsMap]
  );

  const fetchOptions = debounce(async (inputValue) => {
    if (inputValue.length > 2) {
      try {
        const filteredOptions = vanNoOptions?.filter((product) =>
          product.value.toLowerCase().includes(inputValue.toLowerCase())
        );

        return filteredOptions;
      } catch (e) {
        console.log(e);
      }
    }
  });

  const loadOptions = async (inputValue, callback) => {
    try {
      const response = await fetchOptions(inputValue);
      callback(response);
    } catch (error) {
      console.error('Error loading options:', error);
      callback([]);
    }
  };

  const handleClick = async () => {
    try {
      setSuccessIcon(false);
      setInProcess(true);
      const response = await apiCall(
        apiCallConsts?.POST_METHOD,
        'internal/serviceability/pincodes',
        {
          vanNo: selectedVanNos?.map((each) => each?.value),
          pincode: [pinCode],
        }
      );
      setInProcess(false);
      setResults(response?.data);
      setPinCode('');
      setSelectedVanNos([]);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      const response = await apiCall(
        apiCallConsts?.PUT_METHOD,
        'internal/serviceability/pincodes',
        {
          data: results,
        }
      );

      setShowSubmit(!showSubmit);
      setSuccessIcon(!successIcon);
    } catch (error) {}
  };

  const handleToggleEnable = (index) => {
    setSelectedRowIndex(index);
    setShowModal(true);
  };

  const confirmToggle = () => {
    const updatedResults = [...results];
    updatedResults[selectedRowIndex].isEnabled =
      !updatedResults[selectedRowIndex].isEnabled;
    setResults(updatedResults);
    setShowModal(false);
  };

  const ToggleSwitch = ({ isEnabled, onToggle }) => (
    <div
      onClick={onToggle}
      style={{
        display: 'inline-block',
        width: '40px',
        height: '20px',
        background: isEnabled ? '#ddd' : '#ddd',
        borderRadius: '10px',
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          width: '14px',
          height: '14px',
          background: isEnabled ? '#e74c3c' : '#fff',
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: isEnabled ? '24px' : '4px',
          transition: 'left 0.3s',
        }}
      ></div>
    </div>
  );

  return (
    <Container
      fluid
      style={{
        padding: '40px',
        backgroundColor: '#f4f5f7',
        fontFamily: 'Arial, sans-serif',
        minHeight: '100%',
        minHeight: '700px', // Increased Container height
      }}
    >
      <h4
        style={{
          color: '#333',
          marginBottom: '20px',
        }}
      >
        <> Manage Serviceability </>
      </h4>
      <Row style={{ marginBlock: '10px', justifyContent: 'end' }}>
        <Button
          variant="outline-secondary"
          style={{
            borderColor: '#aaa',
            color: '#333',
            fontSize: '14px',
            height: '40px',
            width: '106px',
            borderRadius: '0px',
            padding: '5px 15px',
            marginInline: '10px',
          }}
        >
          <FontAwesomeIcon icon={faRotateRight} size="xl" /> &nbsp; Reset
        </Button>
        <Button
          variant="outline-secondary"
          style={{
            backgroundColor: '#333',
            color: '#fff',
            fontSize: '14px',
            height: '40px',
            width: '106px',
            borderRadius: '0px',
            padding: '5px 15px',
            border: 'none',
            marginInline: '10px',
          }}
        >
          <FontAwesomeIcon icon={faUpload} size="xl" /> &nbsp;Export
        </Button>
      </Row>
      <Row className="justify-content-center" style={{ height: '100%' }}>
        {/* Left Column: Form */}
        <Col
          md={4}
          style={{
            padding: '30px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            marginRight: '30px',
            position: 'relative',
            minHeight: '500px', // Increased Column height
          }}
        >
          <Form>
            <Form.Group controlId="pinCode" style={{ marginBottom: '20px' }}>
              <Form.Label
                style={{ fontSize: '14px', fontWeight: 'bold', color: '#333' }}
              >
                Pin code
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Please Enter Pin Code"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
                style={{
                  padding: '10px',
                  fontSize: '14px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  color: '#333',
                }}
              />
            </Form.Group>
            <Form.Group controlId="vanNo" style={{ marginBottom: '20px' }}>
              <Form.Label
                style={{ fontSize: '14px', fontWeight: 'bold', color: '#333' }}
              >
                VAN No.
              </Form.Label>
              <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                isMulti
                value={selectedVanNos}
                onChange={(e) => {
                  setSelectedVanNos(e);
                }}
                placeholder="Please Enter VAN No."
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minHeight: '38px',
                    fontSize: '14px',
                    borderRadius: '4px',
                    borderColor: '#ddd',
                  }),
                }}
              />
            </Form.Group>
          </Form>
          <Button
            type="submit"
            variant="dark"
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              backgroundColor: '#333',
              border: 'none',
              padding: '10px',
              fontSize: '16px',
              fontWeight: 'bold',
              borderRadius: '4px',
            }}
            onClick={handleClick}
          >
            Submit
          </Button>
        </Col>

        {/* Right Column: Results Table */}
        <Col
          md={7}
          style={
            isProcessing
              ? {
                  backgroundColor: '#fff',
                  padding: '20px',
                  textAlign: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '500px',
                }
              : results.length
              ? {
                  padding: '30px',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  marginRight: '30px',
                  position: 'relative',
                  minHeight: '500px', // Increased Column height
                }
              : {
                  backgroundColor: '#fff',
                  padding: '20px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }
          }
        >
          <Row className="border-bottom pb-2">
            <Col md={2} style={{ color: "'#555" }}>
              <strong>VAN No.</strong>
            </Col>
            <Col md={8} style={{ color: "'#555" }}>
              <strong>Product Title</strong>
            </Col>
            <Col md={2} style={{ color: "'#555" }}>
              <strong>Action</strong>
            </Col>
          </Row>
          {successIcon ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                textAlign: 'center',
                color: '#555', // Match color as per the image
                fontSize: '16px',
                width: '100%',
                maxWidth: '400px',
                height: '90%',
                margin: '0 auto',
              }}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  fontSize: '24px',
                  color: '#777',
                  marginBottom: '10px',
                }}
              />
              <h6 style={{ color: '#777' }}>Successful</h6>
              <div style={{ fontSize: '16px' }}>
                We have Successful Update the serviceability
              </div>
            </div>
          ) : isProcessing ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                textAlign: 'center',
                color: '#555', // Match color as per the image
                fontSize: '16px',
                width: '100%',
                maxWidth: '400px',
                height: '90%',
                margin: '0 auto',
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{
                  fontSize: '24px',
                  color: '#777',
                  marginBottom: '10px',
                }}
              />
              <h6 style={{ color: '#777' }}>Processing Required</h6>
              <div style={{ fontSize: '16px' }}>
                Please wait while we are processing your request.
              </div>
            </div>
          ) : results.length ? (
            <div>
              {results.map((item, index) => (
                <Row
                  key={index}
                  className="align-items-center py-2 border-bottom"
                >
                  <Col md={2}>{item.vanNo}</Col>
                  <Col md={8}>{item.productTitle}</Col>
                  <Col md={2}>
                    <ToggleSwitch
                      isEnabled={item.isEnabled}
                      onToggle={() => handleToggleEnable(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="submit"
                variant="dark"
                style={{
                  position: 'absolute',
                  bottom: '20px',
                  right: '20px',
                  backgroundColor: '#333',
                  border: 'none',
                  padding: '10px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setShowSubmit(!showModal);
                }}
              >
                Apply Change
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                textAlign: 'center',
                color: '#555', // Adjust this to match the color in the image
                fontSize: '16px',
                width: '100%', // Adjust width to suit your layout
                height: '90%',
                maxWidth: '400px', // Limits the width, centering the content
                margin: '0 auto', // Center the container in its parent
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  fontSize: '24px',
                  color: '#777',
                  marginBottom: '10px',
                }} // Adjust icon size and color
              />
              <h6 style={{ color: '#777' }}>Input Required</h6>
              <div style={{ fontSize: '16px' }}>
                Enter a valid Pin Code and VAN No. to proceed.
              </div>
            </div>
          )}
        </Col>
      </Row>

      {/* Confirmation Modal */}
      <Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Body style={{ textAlign: 'center', padding: '30px' }}>
          <h5>Confirm Serviceability Update</h5>
          <p>Are you sure you want to proceed?</p>
          <Button
            variant="light"
            onClick={() => setShowModal(false)}
            style={{ marginRight: '10px' }}
          >
            Cancel
          </Button>
          <Button variant="dark" onClick={confirmToggle}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showSubmit}
        onHide={() => setShowSubmit(false)}
        centered
      >
        <Modal.Body style={{ textAlign: 'center', padding: '30px' }}>
          <i
            className="fas fa-exclamation-circle"
            style={{ fontSize: '24px', color: '#333' }}
          />
          <h5 style={{ marginTop: '15px' }}>Confirm Serviceability Update</h5>
          <p style={{ fontSize: '14px', color: '#555' }}>
            Disabling serviceability will prevent this product from being
            delivered to the selected Pin Code by the third-party courier. The
            product will be removed from the serviceability list once disabled.
            Are you sure you want to proceed?
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="light"
              onClick={() => setShowSubmit(false)}
              style={{ marginRight: '10px', padding: '8px 16px' }}
            >
              Cancel
            </Button>
            <Button
              variant="dark"
              onClick={handleSubmit}
              style={{ padding: '8px 16px' }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ManageServiceability;
