import React, { useEffect, useState } from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import {
  customSizePerPage,
  customTotal,
} from '../../../../components/common/parcelStatusPages';
import { useTranslation } from 'react-i18next';
import BreadCrumbComponent from '../../../../components/common/Breadcrumb';
import DropdownComponent from '../../../../components/common/Dropdown';
import EmptyTable from '../../../../components/common/EmptyTableView';
import { faPen, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { returnRowBGColor } from '../../../../utils/parcels';
import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { StyledSearchBar } from '../../../trips/styles';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { setPageNumberInStore } from '../../../../actions/tablePage';
// import { storesListTableColumns } from "../../pages/oms/stores/utils";

const InventoryPages = (props) => {
  const {
    id,
    title,
    subTitle,
    data,
    storesListTableColumns,
    breadCrumbItems,
    handleFetch,
    searchInputs = [],
    dropdowns = [],
    hideDescription = true,
    descriptionData = {},
    hideBulkActions = false,
    bulkActionOptions,
    enableBulkActions,
    handleItemBulkUpdate,
    showCategoryUpdate = false,
    handleShowCategoryModal,
    hideFetchButton = false,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalPages = Math.ceil((data?.length || 0) / 10);
  const storedPage = useSelector((state) => state?.pageNumber?.[id] || 1);
  const [currentPage, setCurrentPage] = useState(
    storedPage > totalPages ? 1 : storedPage
  );
  const [bulkActionSelectedOption, setBulkActionSelectedOption] =
    useState(null);

  const handleItemUpdate = () => {
    if (bulkActionSelectedOption) {
      handleItemBulkUpdate(bulkActionSelectedOption);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Select an Action'),
          message: t('Please select an action from the dropdown'),
        })
      );
    }
  };

  const handlePageChange = (page) => {
    if (id) {
      dispatch(setPageNumberInStore({ id, page }));
    }
  };

  useEffect(() => {
    setCurrentPage(storedPage > totalPages ? 1 : storedPage);
  }, [storedPage, totalPages]);

  return (
    <div>
      <ToolkitProvider
        keyField="id"
        sort
        search={true}
        data={data}
        columns={storesListTableColumns}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps }) => (
          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: data?.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
              onPageChange: handlePageChange,
              page: currentPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent items={breadCrumbItems} />
                    <h4>{t(title)}</h4>
                    <p className="mb-0">{t(subTitle)}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between m-3">
                  <Row>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          // marginBottom: 16,
                        }}
                      >
                        {searchInputs &&
                          searchInputs.map((searchInput) => (
                            <>
                              <StyledSearchBar
                                size="xs"
                                type="number"
                                placeholder={searchInput.placeholder}
                                onChange={searchInput.onchange}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                          ))}
                        {dropdowns &&
                          dropdowns.map((dropdown) => (
                            <>
                              <DropdownComponent
                                width={300}
                                onChange={dropdown.onchange}
                                placeholder={dropdown.placeholder}
                                options={dropdown.options}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                          ))}
                        {!hideFetchButton && (
                          <Button
                            size={'sm'}
                            onClick={handleFetch}
                            variant="primary"
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            &nbsp;&nbsp;{t('Fetch Data')}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {showCategoryUpdate && (
                      <Button
                        size={'sm'}
                        onClick={handleShowCategoryModal}
                        variant="primary"
                      >
                        <FontAwesomeIcon icon={faPen} />
                        &nbsp;&nbsp; {t('Update Category Stock')}
                      </Button>
                    )}
                  </Row>
                </div>
                {!hideDescription && descriptionData?.isServiceable && (
                  <Card className="my-3 p-2">
                    <Row>
                      <Col lg={2}>
                        <h6>
                          {descriptionData?.isServiceable &&
                            `Serviceable : ${descriptionData?.isServiceable}`}
                        </h6>
                        <h6>
                          {'Max Quantity : ' + descriptionData?.maxQuantity}
                        </h6>
                      </Col>

                      {descriptionData?.shippingModes.map((mode) => (
                        <Col>
                          <React.Fragment key={mode.mode}>
                            <p>
                              {'name : '}
                              <span className="fw-bold">{mode.mode}</span>
                            </p>
                            <p>
                              {'edd : '}
                              <span className="fw-bold">{mode.label}</span>
                            </p>
                            <p>
                              {'shipping cost : '}
                              <span className="fw-bold">
                                {mode.shippingCost}
                              </span>
                            </p>
                            {/* <h6>{"Desc : " + mode.label}</h6>
                              <h6>{"Shipping Cost : " + mode.shippingCost}</h6> */}
                          </React.Fragment>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                )}
                <Card>
                  <div className="table-responsive py-2">
                    <Card.Header>
                      <Row className="d-flex justify-content-between">
                        <Col xs={8} md={6} className="d-flex py-1">
                          {!hideDescription && <h5>StoresList For Pickup</h5>}
                          {!hideBulkActions && (
                            <>
                              <Form.Group
                                id="substatus"
                                className="d-flex align-items-center flex-column"
                              >
                                <DropdownComponent
                                  onChange={(option) => {
                                    setBulkActionSelectedOption(option.value);
                                  }}
                                  width={300}
                                  disabled={!enableBulkActions}
                                  options={bulkActionOptions}
                                  placeholder={t('Select Action')}
                                />
                              </Form.Group>
                              <Button
                                style={{
                                  height: 37,
                                }}
                                size="sm"
                                onClick={handleItemUpdate}
                                disabled={!enableBulkActions}
                                className="ms-3 btn btn-secondary"
                                variant="secondary"
                              >
                                <FontAwesomeIcon icon={faCheck} />
                                &nbsp;&nbsp;{t('Update')}
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          xs={4}
                          md={4}
                          className="d-flex justify-content-md-end py-1"
                        >
                          <Search.SearchBar
                            {...searchProps}
                            placeholder={t('Search any value in table here')}
                          />
                          <Search.ClearSearchButton {...searchProps} />
                        </Col>
                      </Row>
                    </Card.Header>

                    <Table
                      id={'inventoryTable'}
                      rowClasses={(row) => {
                        const rowBGColor = returnRowBGColor(
                          row?.dashboardStatus ||
                            row?.mobileStatus ||
                            row?.status
                        );
                        return `${rowBGColor} border-bottom`;
                      }}
                      noDataIndication={<EmptyTable />}
                      {...baseProps}
                      {...paginationTableProps}
                      bodyClasses="border-0"
                      classes="table-flush dataTable-table"
                    />

                    <Card.Footer>
                      <Row>
                        <Col xs={12} md={4} className="py-1">
                          <Paginator.SizePerPageDropdownStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex align-items-center py-1"
                        >
                          <Paginator.PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                        >
                          <Paginator.PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </div>
                </Card>
              </>
            )}
          </Paginator.PaginationProvider>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default InventoryPages;
