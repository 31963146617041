import React from 'react';

export const vehicleAttendanceReportTableColumns = (headers) => {
  return headers?.map((keyName) => ({
    dataField: keyName,
    text: keyName,
    style: (cell) => ({
      backgroundColor: cell === 'P' ? '#caf5d5' : cell === 'A' ? '#f09693' : '',
    }),
  }));
};

export const monthlyVehicleDeliveryReportColumns = () => {
  return [
    { dataField: 'vehicleNumber', text: 'Vehicle Number' },
    { dataField: 'vehicleType', text: 'Vehicle Type' },
    { dataField: 'deliveryDate', text: 'Delivery Date' },
    { dataField: 'warehouseCode', text: 'Branch Name' },
    { dataField: 'vendorName', text: 'Vendor Name' },
    { dataField: 'products', text: 'Products' },
    { dataField: 'deliveryQuantity', text: 'Delivery Quantity' },
    { dataField: 'isDisplay', text: 'Is Display' },
    { dataField: 'branchDroppedAt', text: 'Branches' },
    { dataField: 'delivered', text: 'Delivered' },
    { dataField: 'pending', text: 'Pending' },
    { dataField: 'droppedathub', text: 'Dropped At Hub' },
    { dataField: 'returnok', text: 'Return OK' },
    { dataField: 'defectivepicked', text: 'Return Defective' },
  ];
};

export const vehiclePaymentsReportColumns = () => {
  return [
    {
      dataField: 'vehicleDetails',
      text: 'Vehicle Number',
      formatter: (cell, row) => {
        return cell.vehicleNumber;
      },
    },
    {
      dataField: 'vehicleDetails',
      text: 'Vehicle Model',
      formatter: (cell, row) => {
        return cell.vehicleType;
      },
    },
    {
      dataField: 'vehicleDetails',
      text: 'Vendor',
      formatter: (cell, row) => {
        return cell.vendorName;
      },
    },
    {
      dataField: 'vehicleDetails',
      text: 'Payment Type',
      formatter: (cell, row) => {
        return cell.paymentMode;
      },
    },
    {
      dataField: 'deliveryAmount',
      text: 'RATE',
      formatter: (cell, row) => {
        return cell.map((item) => <div>{item}</div>);
      },
    },
    {
      dataField: 'totalDays',
      text: 'Total Days',
    },
    {
      dataField: 'totalPresent',
      text: 'Total Present',
    },
    {
      dataField: 'totalDeliveryQuantity',
      text: 'Total Delivery Quantity',
    },
    {
      dataField: 'totalIsDisplay',
      text: 'Total Display/GT',
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
    },
  ];
};
