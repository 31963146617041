import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { setProductsMapInStore } from '../../../actions/oms/allProducts';
import { useTranslation } from 'react-i18next';
import { updateToastInfo } from '../../../actions/settings';

export const useFetchOMSProducts = () => {
  const productsMap = useSelector(
    (state) => state?.allProducts?.productsMap || {}
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchProducts = useCallback(async () => {
    try {
      dispatch(updateSpinnerState(true));
      const fetchedProductsMap = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/feeds/products?isVanOnly=false'
      );
      dispatch(setProductsMapInStore(fetchedProductsMap));
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  }, [dispatch, t]);

  useEffect(() => {
    if (Object.keys(productsMap).length === 0) {
      fetchProducts();
    }
  }, [fetchProducts, productsMap]);
};
