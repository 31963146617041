import React from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '@themesberg/react-bootstrap';

const ActionWithComponents = ({ children }) => {
  return (
    <Dropdown className="me-2">
      <Dropdown.Toggle
        id="dropdown-custom-components"
        variant="light"
        className="shadow-none rounded-0 bg-white"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>

      <Dropdown.Menu align={'start'}>
        {React.Children.map(children, (child, index) => (
          <Dropdown.Item as="div" key={index} className="dropdown-item-style">
            {child}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionWithComponents;
