import React from 'react';
import { Tooltip, OverlayTrigger } from '@themesberg/react-bootstrap';
import { returnCustomerAddressString } from '../table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faRobot } from '@fortawesome/free-solid-svg-icons';
import { returnProductInfo, viewProductInfo } from './tableUtils';
import ActionButton from '../../pages/oms/interBranchTransfers/components/ActionButton';
import { returnUserReadableDate } from '../datetime';

export const schemeTableRedesignColumns = ({
  handleMoreProductsClick,
  options,
  handleSelectedRowUpdate,
}) => {
  const renderToolTip = (props, row) => (
    <Tooltip id="button-tooltip" {...props}>
      Generated By Email.
      {row?.attachments?.[0]?.url && (
        <>
          <br />
          Click to Download.
        </>
      )}
    </Tooltip>
  );
  const columns = [
    {
      dataField: 'schemeCircularNo',
      text: 'SCHEME CIRCULAR',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {row.schemeCircularNo && (
              <div className="d-block fw-bold">
                {returnCustomerAddressString(
                  `No: ${cell}`,
                  null,
                  200,
                  30,
                  'top'
                )}
              </div>
            )}
            <div className="d-block fw-bold">{`Date: ${returnUserReadableDate(
              row.schemeCircularDate
            )}`}</div>
            {row.isAutoGenerated && (
              <div>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => renderToolTip(props, row)}
                >
                  <div className="fs-4">
                    <FontAwesomeIcon
                      icon={faRobot}
                      onClick={() => {
                        if (row?.attachments?.[0]?.url) {
                          window.open(row?.attachments?.[0]?.url, '_blank');
                        }
                      }}
                    />
                  </div>
                </OverlayTrigger>
              </div>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'company',
      text: 'COMPANY',
    },
    {
      dataField: 'productCategory',
      text: 'CATEGORY',
      formatter: (cell, row) => {
        return returnProductInfo(cell, 'Categories');
      },
    },
    {
      dataField: 'products',
      text: 'PRODCUTS',
      formatter: (cell, row) => {
        return viewProductInfo({ cell, handleMoreProductsClick, row });
      },
    },
    {
      dataField: 'validFrom',
      text: 'DATE',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="d-block fw-bold">
              {returnUserReadableDate(row.validFrom)}
            </div>
            <div className="d-block fw-bold">
              {returnUserReadableDate(row.validTill)}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'type',
      text: 'Scheme Type',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-flex flex-column gap-2">
            <SchemeBadge text={cell} />
            {row.actionType && (
              <>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  style={{ right: '30px', position: 'relative' }}
                />
                <SchemeBadge text={row.actionType} />
              </>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'action',
      text: 'action',
      formatter: (cell, row) => {
        return (
          <ActionButton
            options={options}
            onActionClick={(option) => handleSelectedRowUpdate(option, row)}
          />
        );
      },
    },
  ];

  return columns;
};

export const SchemeBadge = ({ text }) => {
  const bgColor = {
    draft: '#F0F0F0',
    negotiation: '#FDEDD9',
    ongoing: '#E2F7FF',
    completed: '#D4F7E8',
    closed: '#FFEBEC',
    requestCancel: '#FF4136',
    requestComplete: '#D4F7E8',
    cancelled: '#FF4136',
    created: '#F2E6FF',
    edited: '#ECF9FD',
    active: '#D4F7E8',
    inactive: '#FDDDDD',
  };

  const color = {
    draft: '#444444',
    negotiation: '#F4921B',
    ongoing: '#00A3E0',
    completed: '#21A871',
    closed: '#E91E23',
    requestCancel: '#FFFFFF',
    requestComplete: '#21A871',
    cancelled: '#FFFFFF',
    created: '#6600C4',
    edited: '#0948B3',
    active: '#21A871',
    inactive: '#E91E23',
  };
  return (
    <div
      style={{
        backgroundColor: bgColor[text],
        color: color[text],
        fontWeight: '500',
        padding: 2,
        width: '126px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {text?.toUpperCase()}
    </div>
  );
};

export const ViewProductsTable = (data) => {
  const columns = [
    {
      dataField: 'productName',
      text: 'Product Name',
    },
    {
      dataField: 'vanNo',
      text: 'Van No',
    },
    {
      dataField: 'valuationType',
      text: 'Valuation Type',
    },
    {
      dataField: 'payout',
      text: 'Payout',
    },
  ];

  if (data.type?.[0] === 'Sell-out' && data.products?.[0]?.cappingType) {
    columns.push(
      ...[
        {
          dataField: 'cappingType',
          text: 'Capping Type',
        },
        {
          dataField: 'cappingQuantity',
          text: 'Capping Quantity',
        },
      ]
    );
  }
  return columns;
};

export const schemesUsersListColumns = () => {};
