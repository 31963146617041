import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';

const CancelEWayBillModal = ({ show, handleClose, onCancel, onSuccess }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (isError) setIsError(false); // Remove error styling once checked
  };

  const onHide = () => {
    resetFields();
    handleClose();
  };

  let resetFields = () => {
    setIsError(false);
    setIsChecked(false);
  };
  const handleGenerateClick = () => {
    if (!isChecked) {
      setIsError(true); // Highlight checkbox in red if not checked
    } else {
      setIsError(false);
      onSuccess(); // Call success action when checkbox is checked
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      centered
      style={{
        textAlign: 'center',
      }}
    >
      <Modal.Body
        style={{
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          size={'xl'}
          style={{ marginBlock: '10px' }}
        />
        <h5
          style={{
            fontWeight: '600',
            fontSize: '22px',
            color: '#333',
            marginBottom: '15px',
            marginBlock: '10px',
          }}
        >
          Cancel Existing E-Way Bill Before Generating New
        </h5>
        <p
          style={{
            fontSize: '14px',
            color: '#555',
            lineHeight: '1.6',
            marginBottom: '20px',
          }}
        >
          To generate a new E-Way Bill, you must first cancel the existing one
          from the <strong>Government Portal</strong>. Once deleted, return here
          to generate the new E-Way Bill.
        </p>
        <Form.Group
          controlId="confirmCancel"
          style={{
            textAlign: 'left',
            margin: '0 auto',
            display: 'inline-block',
          }}
        >
          <Form.Check
            type="checkbox"
            label="I have canceled the existing E-Way Bill from the Government Portal."
            checked={isChecked}
            onChange={handleCheckboxChange}
            style={{
              fontSize: '14px',
              color: '#555',
              accentColor: isChecked ? '#007BFF' : 'red', // Active blue color for checkbox
              cursor: 'pointer',
            }}
          />
          {isError && (
            <small
              style={{
                color: 'red',
                display: 'block',

                marginTop: '5px',
              }}
            >
              You must confirm the cancellation before proceeding.
            </small>
          )}
        </Form.Group>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '50px',
            width: '90%',
          }}
        >
          <Button
            variant="link"
            className="text-gray"
            style={{
              padding: '10px 0',
              width: '30%',
              fontSize: '14px',
              fontWeight: '500',
              backgroundColor: '#F0F0F0',
              borderRadius: '4px',
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            style={{
              padding: '10px 0',
              width: '30%',
              fontSize: '14px',
              fontWeight: '500',
              color: '#fff',
              backgroundColor: isChecked ? '#444444' : '#ccc', // Gray background when inactive
              borderRadius: '4px',
              cursor: isChecked ? 'pointer' : 'not-allowed', // Disable pointer if not checked
            }}
            onClick={handleGenerateClick}
            disabled={!isChecked} // Button disabled when not checked
          >
            Generate New E-Way Bill
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelEWayBillModal;
